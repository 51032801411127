import { Component, Input, OnInit } from '@angular/core';
import { PopupService } from '../../services/popup.service';
import { SchoolsPoiProperties } from '../../../menu/right-menu/layers-menu/education/education.service';
import { CrimeRateByYear } from '../../../menu/right-menu/layers-menu/points.service';
import { Feature } from '@turf/helpers';
import {
  H3_RES5_LEVEL_LAYER,
  LayerStoreService,
} from '../../services/layer-store.service';
import { MapBoxService } from '../../mapbox.service';
import { MapUrlService } from '../../services/map-url.service';
import { SelectedCellService } from '../../services/selected-cell.service';
import {
  FavoriteCell,
  FavoritesService,
} from '../../../favorites/favorites.service';
import { ModalService } from '../../../../shared/services/modal.service';
import { UserAccessService } from '../../../../user/access/user-access.service';
import { featuresToCompareToNationalPopup } from '../../../../shared/types/feature-data-type';
import { MapHttpService } from '../../services/map-http.service';
import { AiService } from '../../../../shared/services/ai.service';

export const DEFAULT_POPUP = 'DEFAULT_POPUP';
export const SCHOOLS_POPUP = 'SCHOOLS_POPUP';
export const ORI_POPUP = 'ORI_POPUP';
export const HOVER_POPUP = 'HOVER_POPUP';

export type PopupData = {
  type: typeof DEFAULT_POPUP | typeof SCHOOLS_POPUP | typeof ORI_POPUP | typeof HOVER_POPUP
  properties: Feature['properties'] | SchoolsPoiProperties | CrimeRateByYear
}

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.scss',
  '../../../../app.component.scss']
})
export class MapPopupComponent implements OnInit {
  @Input() data!: PopupData

  public title!: string

  public isUnauthorized!: boolean

  constructor(private mapboxService: MapBoxService,
              private urlService: MapUrlService,
              private selectedCellService: SelectedCellService,
              private modalService: ModalService,
              public accessService: UserAccessService,
              private http: MapHttpService,
              public popupService: PopupService,
              public layerStore: LayerStoreService,
              public favoritesService: FavoritesService,
              private aiService: AiService,
  ) { }

  ngOnInit(): void {
    this.isUnauthorized = this.accessService.getIsUnauthorized()
    if (this.data.type !== HOVER_POPUP) {
      this.popupService.handlePopupTitle();
      this.loadWrittenInStateFeatures(this.selectedCellService.getSelectedCellGeoId()!)
    }
  }

  public handleClose(): void {
    this.selectedCellService.deselectCell()
    this.popupService.popup.remove();
  }

  public toggleSeeMore(): void {
    this.popupService.isSeeMoreActive = !this.popupService.isSeeMoreActive;

    // Simulate map.move simply to recalculate popup position relative to map bounds and rerender it accordingly
    this.mapboxService.map.flyTo({
      center: this.mapboxService.map.getCenter()
    })
  }

  public handleDownloadXlsx(): void {
    this.popupService.downloadXlsx()
  }

  public handleAiExperimentClick(): void {
    this.aiService.requestExperimentalLLm(this.selectedCellService.getSelectedCellGeoId()!, this.selectedCellService.getCellType()!)
      .subscribe(response => {
        console.log(response);
        alert(response.object)
      });
  }

  public handleShare(): void {
    this.urlService.copyUrlToClipboard()
  }

  public handleAddToFavorites(): void {
    this.modalService.handleFavoriteCellPersonalizationModal(this.selectedCellService.getSelectedCellGeoId()!)
  }

  public handleRemoveFromFavorites(): void {
    this.favoritesService.removeFavoriteCell(this.selectedCellService.getSelectedCellGeoId()!, this.selectedCellService.getCellType()!)
  }

  public isFeatureFavorite(): boolean {
   return !!this.favoritesService.favoritePlaces.find((el: FavoriteCell) => el.geoId === this.selectedCellService.getSelectedCellGeoId())
  }

  private loadWrittenInStateFeatures(id: string) {
    this.http.getDataForSingleCell(id, this.selectedCellService.getCellType()!, featuresToCompareToNationalPopup)
      .subscribe(data => {
        this.data.properties = {...this.data.properties, ...data.object}
      })
  }

  protected readonly ORI_POPUP = ORI_POPUP;
  protected readonly SCHOOLS_POPUP = SCHOOLS_POPUP;
  protected readonly DEFAULT_POPUP = DEFAULT_POPUP;
  protected readonly HOVER_POPUP = HOVER_POPUP;
  protected readonly H3_RES5_LEVEL_LAYER = H3_RES5_LEVEL_LAYER;
}
