import { UserPersonalData } from '../authentication.service';
import {
  AGE_SEX_DISTRIBUTION_CHART,
  AIR_POLLUTION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  BEAUTY_POI_CHART,
  BIDEN_VS_TRUMP_2020_POPULATION,
  BINGE_DRINKING_RATE,
  BUSINESS_CRIME_RATE,
  CANCER_PREVALENCE,
  COMMUNITY_CRIME_RATE,
  COMPETITORS_POI_CHART,
  CONVENIENCE_POI_CHART,
  CRIME_RATE,
  CUMULATIVE_TRAFFIC_INTENSITY,
  DIABETES_PREVALENCE,
  EDUCATION_ATTAINMENT_CHART,
  EMPLOYMENT_CHART,
  FAMILY_POI_CHART,
  FINANCIAL_SERVICES_POI_CHART,
  GENDER_PAY_GAP,
  GINI_INDEX,
  GROSS_RENT_DISTRIBUTION_CHART,
  HARRIS_VS_TRUMP_2024_POPULATION,
  HEALTH_INDEX_BY_COUNTRY,
  HEALTH_INDEX_BY_STATE,
  HEALTH_INDEX_CONST,
  HEALTHCARE_POI_CHART,
  HEART_DISEASES_PREVALENCE,
  HOME_OWNERSHIP_RATIO,
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  HOUSEHOLDS,
  HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
  hwwFeatures,
  INCOME_DISTRIBUTION_CHART,
  LIFE_EXPECTANCY,
  MEDIAN_GROSS_RENT,
  MEDIAN_GROSS_RENT_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  MEDIAN_HOUSING_SALE_COST,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_INCOME,
  NUMBER_OF_ROOMS_CHART,
  OBESITY_RATE,
  OUTDOOR_POI_CHART,
  PATIENT_TO_DENTIST_RATIO,
  PATIENT_TO_PHYSICIAN_RATIO,
  PATIENT_TO_THERAPIST_RATIO,
  PEOPLE_PER_MI2,
  POPULATION,
  POPULATION_GROWTH_TREND,
  POPULATION_TREND_CHART,
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  RACE_DISTRIBUTION_CHART,
  RETIREMENT_POI_CHART,
  ROAD_DENSITY,
  ROAD_DENSITY_CHART,
  SCHOOLS_POI,
  SENATE_ELECTION_2022_RESULTS,
  SMOKING_RATE,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  SPECIAL_HEALTHCARE_NEEDS,
  TARGET_AUDIENCE,
  targetAudienceIndustries,
  TENURE_CHART,
  TRAFFIC_INTENSITY_CHART,
  UNEMPLOYMENT_RATIO,
  UNHEALTHY_BEHAVIOUR_INDEX,
  UNINSURED_RATIO,
  VACANCY_CHART,
  voteDataFeatures,
  WEALTH_INDEX_BY_COUNTRY,
  WEALTH_INDEX_BY_STATE,
  WEALTH_INDEX_CONST,
  WISE_INDEX_BY_COUNTRY,
  WISE_INDEX_BY_STATE,
  WISE_INDEX_CONST,
} from '../../shared/types/feature-data-type';
import { FeatureType } from '../access/user-access.service';
import {
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  ORI_LAYER,
  STATE_LEVEL_LAYER,
} from '../../map/mapbox/services/layer-store.service';

export const ALL_FEATURES_AVAILABLE = 'ALL_FEATURES_AVAILABLE';

export const COMMUNITY_COMPASS_REPORT_SAMPLE = 'https://uploads-ssl.webflow.com/645fc9b1f2ae787ad9542f5d/65525e3faeddfb54632d2fe7_Community%20Compass%20report.pdf'
export const OPEN_HOUSE_FLYER_REPORT_SAMPLE = 'https://uploads-ssl.webflow.com/645fc9b1f2ae787ad9542f5d/66bddfdbe2857c83cbe388d3_flyer.pdf'
export const COMPARATIVE_LOCATION_ANALYSIS_REPORT_SAMPLE = 'https://cdn.prod.website-files.com/645fc9b1f2ae787ad9542f5d/6624318434fe9596295ad508_Comparative%20Location%20Analysis.pdf'
export const LOCATION_INTELLIGENCE_REPORT_SAMPLE = 'https://cdn.prod.website-files.com/645fc9b1f2ae787ad9542f5d/6624318434fe9596295ad508_Comparative%20Location%20Analysis.pdf'
export const FEASIBILITY_STUDY_REPORT_SAMPLE = 'https://cdn.prod.website-files.com/645fc9b1f2ae787ad9542f5d/672bb82c544c994dcb5c4f12_Feasibility%20study.pdf'

export interface IndicatorsDescription {
  description: string;
  groups: GroupDescription[]
}

export interface GroupDescription {
  groupTitle: string;
  bulletList: string[];
}

type ReportDescription = {
  reportsIncludedDescription?: string;
  reportsPaidDescription?: string;
  includedInSubscription?: boolean;
  isReadyToOrder: boolean;
  sampleURL?: string;
}

export type ReportTypeDescription = {
  reportsBase?: ReportDescription[];
  reportsMonthly?: ReportDescription[];
  reportsYearly?: ReportDescription[];
}

export type PlainTextFeatureDescription = string

export interface SubscriptionPlanDescription {
  planName: string;
  preformattedPlanName?: string,
  priceMonthAnnually?: string;
  priceYearly?: string;
  priceMonthly?: string;
  priceLifetime?: string;
  yearlyDiscountPercents?: string;
  requestKey: SubscriptionPlans;
  accessibleFeatures: FeatureType[] | typeof ALL_FEATURES_AVAILABLE;
  accessibleLevels: string[];
  accessibleFeaturesDescription: {
    indicatorsDescription: IndicatorsDescription;
    reportTypesDescription: ReportTypeDescription;
    plainTextFeatureDescription?: PlainTextFeatureDescription[];
  }
  inaccessibleFeatureText: string[];
  inaccessibleReportTypes?: ReportTypes[];
  accessibleReportTypes?: ReportTypes[] | null;
  // If plan is not meant to be seen by user
  notAvailableForUser?: boolean
}

export interface TrialDescription {
  planName: string
  accessibleFeatures: FeatureType[] | typeof ALL_FEATURES_AVAILABLE
  accessibleLevels: string[]
  accessibleFeaturesText: string[]
}

export interface UnauthorizedDescription {
  accessibleFeatures: FeatureType[] | typeof ALL_FEATURES_AVAILABLE
  accessibleLevels: string[]
  actionsLimit: number,
}

/**
 * Types of Map AI subscription user can choose from.
 * <a href="https://docs.google.com/spreadsheets/d/10mwuPckbuoKjwAwojio7mOz0VJ0N0OXI7m0tj7wM7C8/edit?usp=sharing">Google doc</a>
 */
export enum SubscriptionPlans {
  /**
   * Cheapest: some data for states & counties only.
   */
  KNOW_YOUR_COUNTRY = 'KNOW_YOUR_COUNTRY',
  /**
   * Individual subscriptions: Cells and some data added.
   */
  COMMUNITY_COMPASS = 'COMMUNITY_COMPASS',
  /**
   * Special kind of subscription for realtors.
   * GB and Arthur insisted it must be separated from {@link SubscriptionPlans#ENTERPRISE},
   * since realtors have special documents which must be checked as well as special needs.
   */
  REALTOR = 'REALTOR',
  ENTERPRISE = 'ENTERPRISE',
  FULL_ACCESS = 'FULL_ACCESS'
}

export enum ReportTypes {
  COMMUNITY_COMPASS = 'COMMUNITY_COMPASS',
  OPEN_HOUSE_FLYER = 'OPEN_HOUSE_FLYER',
  LOCATION_INTELLIGENCE = 'LOCATION_INTELLIGENCE',
  FEASIBILITY_STUDY = 'FEASIBILITY_STUDY',
  COMPARATIVE_LOCATION = 'COMPARATIVE_LOCATION',
  MAP_AI_LOCATION_OVERVIEW_FOR_BEAUTY_SALONS = 'MAP_AI_LOCATION_OVERVIEW_FOR_BEAUTY_SALONS'
}


/**
 Texts and pricing data for each subscription plan https://docs.google.com/document/d/187R5GUQTvRauJ_CJftGKnJTYktSesDtPlLaDlmcK1LE/
 Feature access table https://docs.google.com/spreadsheets/d/1CJ2uHd4AVTNmAIJzIdTHm9WY7MatiMLXAeDypBfmQ8Q/
 */
export namespace SubscriptionPlans {
  export const description: { [subscription in SubscriptionPlans]: SubscriptionPlanDescription } = {
    [SubscriptionPlans.KNOW_YOUR_COUNTRY]: {
      planName: 'Know your country',
      preformattedPlanName: 'Know<br>your country',
      priceYearly: '$49.95',
      priceMonthly: '$4.95',
      priceMonthAnnually: '$59.95',
      yearlyDiscountPercents: '17% OFF',
      requestKey: SubscriptionPlans.KNOW_YOUR_COUNTRY,
      accessibleFeatures: [
        POPULATION,
        POPULATION_GROWTH_TREND,
        PEOPLE_PER_MI2,
        HOUSEHOLDS,
        AGE_SEX_DISTRIBUTION_CHART,
        POPULATION_TREND_CHART,
        ...hwwFeatures,
        MEDIAN_INCOME,
        MEDIAN_HOUSEHOLD_INCOME_TREND,
        GINI_INDEX,
        UNEMPLOYMENT_RATIO,
        INCOME_DISTRIBUTION_CHART,
        EMPLOYMENT_CHART,
        CRIME_RATE,
        MEDIAN_HOUSING_SALE_COST_TREND,
        MEDIAN_GROSS_RENT_TREND,
        MEDIAN_GROSS_RENT,
        MEDIAN_HOUSING_SALE_COST,
        HOME_OWNERSHIP_RATIO,
        VACANCY_CHART,
        GROSS_RENT_DISTRIBUTION_CHART,
        HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
        NUMBER_OF_ROOMS_CHART,
        LIFE_EXPECTANCY,
        UNINSURED_RATIO,
        PATIENT_TO_PHYSICIAN_RATIO,
        PATIENT_TO_DENTIST_RATIO,
        PATIENT_TO_THERAPIST_RATIO,
        SMOKING_RATE,
        BINGE_DRINKING_RATE,
        OBESITY_RATE,
        DIABETES_PREVALENCE,
        CANCER_PREVALENCE,
        HEART_DISEASES_PREVALENCE,
        EDUCATION_ATTAINMENT_CHART,
        TRAFFIC_INTENSITY_CHART,
        CUMULATIVE_TRAFFIC_INTENSITY,
        ROAD_DENSITY,
        ROAD_DENSITY_CHART,
        AIR_POLLUTION,
        FAMILY_POI_CHART,
        RETIREMENT_POI_CHART,
        CONVENIENCE_POI_CHART,
        HEALTHCARE_POI_CHART,
        BEAUTY_POI_CHART,
        FINANCIAL_SERVICES_POI_CHART,
        OUTDOOR_POI_CHART,
        GENDER_PAY_GAP,
        UNHEALTHY_BEHAVIOUR_INDEX,
        SPECIAL_HEALTH_CARE_NEEDS_INDEX,
        RACE_DISTRIBUTION_CHART,
        ...voteDataFeatures,
        BACHELORS_DEGREE_OR_HIGHER_FRACTION,
        HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
        SENATE_ELECTION_2022_RESULTS
      ],
      accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
      accessibleReportTypes: null,
      inaccessibleFeatureText: ['High resolution 5-mile and 1-mile cell data'],
      accessibleFeaturesDescription: {
        indicatorsDescription: {
            description: 'Over 50 indicators and trends available at state and county levels.',
            groups: [
              {
                groupTitle: 'Area wellness',
                bulletList: [
                  'Health index',
                  'Wealth index',
                  'Wisdom index',
                  'Franklin index']
              },
              {
                groupTitle: 'Population',
                bulletList: [
                  'Population number trend & density',
                  'Number of households',
                  'Age-sex pyramid',
                  'Race & ethnicity']
              },
              {
                groupTitle: 'Income',
                bulletList: ['Median household income',
                  'Income trend & distribution',
                  'Income inequality index',
                  'Employment status',
                  'Gender pay gap']
              },
              {
                groupTitle: 'Education',
                bulletList: ['Educational attainment']
              },
              {
                groupTitle: 'Crime',
                bulletList: ['Total crime rate']
              },
              {
                groupTitle: 'Housing',
                bulletList: [
                  'Median home value & gross rent',
                  'Home value & gross rent trend and distribution',
                  'Homeownership rate',
                  'Vacancy',
                  'Number of rooms'
                ]
              },
              {
                groupTitle: 'Health related',
                bulletList: [
                  'Life expectancy',
                  'Uninsured rate',
                  'Population-to-medical staff ratios',
                ]
              },
              {
                groupTitle: 'Lifestyle risks',
                bulletList: [
                  'Smoking rate',
                  'Binge drinking rate',
                  'Obesity rate',
                  'Unhealthy habits prevalence'
                ]
              },
              {
                groupTitle: 'Chronic diseases',
                bulletList: [
                  'Diabetes prevalence',
                  'Cancer prevalence',
                  'Heart disease prevalence',
                  'Regional health conditions'
                ]
              },
              {
                groupTitle: 'Physical environment',
                bulletList: [
                  'Traffic intensity',
                  'Road density',
                  'Air pollution',
                ]
              },
              {
                groupTitle: 'Amenities',
                bulletList: [
                  'Playgrounds, childcares & parks',
                  'Shops, gas stations & restaurants',
                  'Beauty & spa',
                  'Banks & ATMs',
                  'Financial services',
                  'Hospitals, clinics & dentists',
                  'Retirement facilities',
                  'And more'
                ]
              }
            ]
          },
        plainTextFeatureDescription: [
          'Nationwide search',
          'Up-to-date & accurate data'
        ],
        reportTypesDescription: {
          reportsBase: []
        }
      }
    },
    [SubscriptionPlans.COMMUNITY_COMPASS]: {
      planName: 'Community compass',
      preformattedPlanName: 'Community<br>compass',
      priceMonthly: '$7.95',
      priceYearly: '$49.95',
      priceMonthAnnually: '$95.95',
      yearlyDiscountPercents: '52% OFF',
      requestKey: SubscriptionPlans.COMMUNITY_COMPASS,
      accessibleFeatures: [
        POPULATION,
        AGE_SEX_DISTRIBUTION_CHART,
        ...hwwFeatures,
        INCOME_DISTRIBUTION_CHART,
        MEDIAN_INCOME,
        GINI_INDEX,
        UNEMPLOYMENT_RATIO,
        CRIME_RATE,
        COMMUNITY_CRIME_RATE,
        ORI_LAYER,
        MEDIAN_HOUSING_SALE_COST_TREND,
        MEDIAN_GROSS_RENT_TREND,
        MEDIAN_GROSS_RENT,
        MEDIAN_HOUSING_SALE_COST,
        HOME_OWNERSHIP_RATIO,
        VACANCY_CHART,
        GROSS_RENT_DISTRIBUTION_CHART,
        HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
        NUMBER_OF_ROOMS_CHART,
        LIFE_EXPECTANCY,
        PATIENT_TO_PHYSICIAN_RATIO,
        PATIENT_TO_DENTIST_RATIO,
        PATIENT_TO_THERAPIST_RATIO,
        SMOKING_RATE,
        BINGE_DRINKING_RATE,
        OBESITY_RATE,
        DIABETES_PREVALENCE,
        CANCER_PREVALENCE,
        HEART_DISEASES_PREVALENCE,
        EDUCATION_ATTAINMENT_CHART,
        SCHOOLS_POI,
        AIR_POLLUTION,
        FAMILY_POI_CHART,
        RETIREMENT_POI_CHART,
        CONVENIENCE_POI_CHART,
        HEALTHCARE_POI_CHART,
        BEAUTY_POI_CHART,
        OUTDOOR_POI_CHART,
        GENDER_PAY_GAP,
        UNHEALTHY_BEHAVIOUR_INDEX,
        SPECIAL_HEALTH_CARE_NEEDS_INDEX,
        RACE_DISTRIBUTION_CHART,
        ...voteDataFeatures,
        HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
        SENATE_ELECTION_2022_RESULTS
      ],
      inaccessibleFeatureText: [],
      accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER, H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
      accessibleReportTypes: [ReportTypes.COMMUNITY_COMPASS],
      accessibleFeaturesDescription: {
        indicatorsDescription: {
            description: 'Over 30 neighborhood indicators and trends available at all levels, including 5-mile and 1-mile cells.',
            groups: [
              {
                groupTitle: 'Area wellness',
                bulletList: [
                  'Health index',
                  'Wealth index',
                  'Wisdom index',
                  'Franklin index']
              },
              {
                groupTitle: 'Housing',
                bulletList: [
                  'Median home value & gross rent',
                  'Home value & gross rent trend and distribution',
                  'Homeownership rate',
                  'Vacancy',
                  'Number of rooms'
                ]
              },
              {
                groupTitle: 'Crime',
                bulletList: ['Total crime rate',
                  'Community crime rate',
                  'Number of committed crimes by type & location']
              },
              {
                groupTitle: 'School ranking',
                bulletList: ['Public & private school ranks and details']
              },
              {
                groupTitle: 'Nearby amenities',
                bulletList: [
                  'Playgrounds, childcares & parks',
                  'Shops, gas stations & restaurants',
                  'Hospitals, clinics & dentists',
                  'Beauty & spa',
                  'Retirement facilities',
                  'And more',
                ]
              },
              {
                groupTitle: 'Health related',
                bulletList: [
                  'Life expectancy',
                  'Population-to-medical staff ratios',
                ]
              },

              {
                groupTitle: 'Lifestyle risks',
                bulletList: [
                  'Smoking rate',
                  'Binge drinking rate',
                  'Obesity rate',
                  'Unhealthy habits prevalence'
                ]
              },
              {
                groupTitle: 'Chronic diseases',
                bulletList: [
                  'Diabetes prevalence',
                  'Cancer prevalence',
                  'Heart disease prevalence',
                  'Regional health conditions'
                ]
              },
              {
                groupTitle: 'Demographics',
                bulletList: ['Population',
                  'Age-sex pyramid',
                  'Race & ethnicity',
                  'Median household income',
                  'Income distribution',
                  'Income Inequality Index',
                  'Unemployment rate',
                  'Educational attainment',
                  'Gender pay gap']
              },
              {
                groupTitle: 'Physical environment',
                bulletList: [
                  'Air pollution',
                ]
              },
            ]
          },
        plainTextFeatureDescription: [
          'Nationwide search',
          'Up-to-date & accurate data',
          'High-resolution 5-mile and 1-mile cell data'
        ],
        reportTypesDescription: {
          reportsBase: [
            {
              reportsPaidDescription: '$9.95 per Community Compass PDF report',
              includedInSubscription: false,
              sampleURL: COMMUNITY_COMPASS_REPORT_SAMPLE,
              isReadyToOrder: true
            }
          ]

        }
      }
    },
    [SubscriptionPlans.REALTOR]: {
      planName: 'Realtors’ Special',
      preformattedPlanName: 'Realtors’<br>Special',
      priceYearly: '$249.95',
      priceMonthly: '$24.95',
      priceMonthAnnually: '$299.95',
      yearlyDiscountPercents: '17% OFF',
      requestKey: SubscriptionPlans.REALTOR,
      accessibleFeatures: [
        POPULATION,
        AGE_SEX_DISTRIBUTION_CHART,
        ...hwwFeatures,
        MEDIAN_INCOME,
        GINI_INDEX,
        UNEMPLOYMENT_RATIO,
        CRIME_RATE,
        COMMUNITY_CRIME_RATE,
        ORI_LAYER,
        MEDIAN_HOUSING_SALE_COST_TREND,
        MEDIAN_GROSS_RENT_TREND,
        MEDIAN_GROSS_RENT,
        MEDIAN_HOUSING_SALE_COST,
        HOME_OWNERSHIP_RATIO,
        VACANCY_CHART,
        GROSS_RENT_DISTRIBUTION_CHART,
        HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
        NUMBER_OF_ROOMS_CHART,
        LIFE_EXPECTANCY,
        PATIENT_TO_PHYSICIAN_RATIO,
        PATIENT_TO_DENTIST_RATIO,
        PATIENT_TO_THERAPIST_RATIO,
        SPECIAL_HEALTHCARE_NEEDS,
        EDUCATION_ATTAINMENT_CHART,
        SCHOOLS_POI,
        AIR_POLLUTION,
        FAMILY_POI_CHART,
        RETIREMENT_POI_CHART,
        CONVENIENCE_POI_CHART,
        HEALTHCARE_POI_CHART,
        BEAUTY_POI_CHART,
        OUTDOOR_POI_CHART,
        GENDER_PAY_GAP,
        UNHEALTHY_BEHAVIOUR_INDEX,
        SPECIAL_HEALTH_CARE_NEEDS_INDEX
      ],
      inaccessibleFeatureText: [],
      accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER, H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
      accessibleReportTypes: [ReportTypes.COMMUNITY_COMPASS, ReportTypes.OPEN_HOUSE_FLYER],
      accessibleFeaturesDescription: {
        indicatorsDescription: {
            description: 'Over 30 neighborhood indicators and trends available at all levels, including 5-mile and 1-mile cells.',
            groups: [
              {
                groupTitle: 'Area wellness',
                bulletList: [
                  'Health index',
                  'Wealth index',
                  'Wisdom index',
                  'Franklin index']
              },
              {
                groupTitle: 'Real estate',
                bulletList: [
                  'Median home value & gross rent',
                  'Home value & gross rent trend and distribution',
                  'Homeownership rate',
                  'Vacancy',
                  'Number of rooms'
                ]
              },
              {
                groupTitle: 'Safety',
                bulletList: ['Total crime rate',
                  'Community crime rate',
                  'Number of committed crimes by type & location']
              },
              {
                groupTitle: 'School ranking',
                bulletList: ['Public & private school ranks and details']
              },
              {
                groupTitle: 'Nearby amenities',
                bulletList: [
                  'Playgrounds, childcares & parks',
                  'Shops, gas stations & restaurants',
                  'Hospitals, clinics & dentists',
                  'Beauty & spa',
                  'Retirement facilities',
                  'And more',
                ]
              },
              {
                groupTitle: 'Health',
                bulletList: ['Life expectancy',
                  'Population-to-medical staff ratios',
                  'Unhealthy habits prevalence',
                  'Chronic diseases']
              },
              {
                groupTitle: 'Demographics',
                bulletList: ['Population',
                  'Age-sex pyramid',
                  'Median household income',
                  'Income Inequality Index',
                  'Unemployment rate',
                  'Educational attainment',
                  'Gender pay gap']
              },
              {
                groupTitle: 'Physical environment',
                bulletList: [
                  'Air pollution',
                ]
              }]
          },
        plainTextFeatureDescription: [
          'Nationwide search',
          'Up-to-date & accurate data',
          'High-resolution 5-mile and 1-mile data'
        ],
        reportTypesDescription: {
          reportsMonthly: [
            {
              reportsIncludedDescription: '4 free open house flyers',
              reportsPaidDescription: '$4.99 per extra report',
              includedInSubscription: true,
              sampleURL: OPEN_HOUSE_FLYER_REPORT_SAMPLE,
              isReadyToOrder: true
            },
            {
              reportsPaidDescription: '$9.95 per Community Compass report',
              includedInSubscription: false,
              sampleURL: COMMUNITY_COMPASS_REPORT_SAMPLE,
              isReadyToOrder: true
            }
          ],
          reportsYearly: [
            {
              reportsIncludedDescription: '48 free open house flyers',
              reportsPaidDescription: '$4.99 per extra report',
              includedInSubscription: true,
              sampleURL: OPEN_HOUSE_FLYER_REPORT_SAMPLE,
              isReadyToOrder: true
            },
            {
              reportsPaidDescription: '$9.95 per Community Compass report',
              includedInSubscription: false,
              sampleURL: COMMUNITY_COMPASS_REPORT_SAMPLE,
              isReadyToOrder: true
            }
          ],
          reportsBase: []
        }
      }
    },
    [SubscriptionPlans.ENTERPRISE]: {
      planName: 'Enterprise Location Intelligence',
      preformattedPlanName: 'Enterprise Location<br>Intelligence',
      priceYearly: '$2,499.95',
      priceMonthly: '$249.95',
      priceMonthAnnually: '$2,999.95',
      yearlyDiscountPercents: '17% OFF',
      requestKey: SubscriptionPlans.ENTERPRISE,
      accessibleFeatures: [
        POPULATION,
        POPULATION_GROWTH_TREND,
        PEOPLE_PER_MI2,
        HOUSEHOLDS,
        AGE_SEX_DISTRIBUTION_CHART,
        POPULATION_TREND_CHART,
        MEDIAN_INCOME,
        MEDIAN_HOUSEHOLD_INCOME_TREND,
        UNEMPLOYMENT_RATIO,
        INCOME_DISTRIBUTION_CHART,
        EMPLOYMENT_CHART,
        TARGET_AUDIENCE,
        ...targetAudienceIndustries,
        CRIME_RATE,
        BUSINESS_CRIME_RATE,
        ORI_LAYER,
        MEDIAN_HOUSING_SALE_COST_TREND,
        MEDIAN_GROSS_RENT_TREND,
        MEDIAN_GROSS_RENT,
        MEDIAN_HOUSING_SALE_COST,
        UNINSURED_RATIO,
        PATIENT_TO_PHYSICIAN_RATIO,
        PATIENT_TO_DENTIST_RATIO,
        PATIENT_TO_THERAPIST_RATIO,
        DIABETES_PREVALENCE,
        CANCER_PREVALENCE,
        HEART_DISEASES_PREVALENCE,
        SPECIAL_HEALTHCARE_NEEDS,
        EDUCATION_ATTAINMENT_CHART,
        CUMULATIVE_TRAFFIC_INTENSITY,
        TRAFFIC_INTENSITY_CHART,
        ROAD_DENSITY,
        ROAD_DENSITY_CHART,
        COMPETITORS_POI_CHART,
        HEALTHCARE_POI_CHART,
        BEAUTY_POI_CHART,
        FINANCIAL_SERVICES_POI_CHART,
        SPECIAL_HEALTH_CARE_NEEDS_INDEX,
        RACE_DISTRIBUTION_CHART,
        BACHELORS_DEGREE_OR_HIGHER_FRACTION
      ],
      accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER, H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
      inaccessibleFeatureText: [],
      accessibleReportTypes: [
        ReportTypes.LOCATION_INTELLIGENCE,
        ReportTypes.COMPARATIVE_LOCATION,
        ReportTypes.MAP_AI_LOCATION_OVERVIEW_FOR_BEAUTY_SALONS
      ],
      accessibleFeaturesDescription: {
        indicatorsDescription: {
            description: 'Over 25 business indicators and trends available at all levels, including 5-mile and 1-mile cells.',
            groups: [
              {
                groupTitle: 'Demographics',
                bulletList: [
                  'Population number, trend & density',
                  'Number of households',
                  'Age-sex pyramid',
                  'Median household income',
                  'Income trend & distribution',
                  'Employment status',
                  'Educational attainment']
              },
              {
                groupTitle: 'Health related',
                bulletList: [
                  'Uninsured rate',
                  'Population-to-medical staff ratios',
                ]
              },
              {
                groupTitle: 'Healthcare needs',
                bulletList: [
                  'Diabetes prevalence',
                  'Cancer prevalence',
                  'Heart disease prevalence',
                  'Regional health conditions',
                ]
              },
              {
                groupTitle: 'Target audience',
                bulletList: ['C-stores',
                  'Supermarkets',
                  'Pharmacies',
                  'Beauty']
              },
              {
                groupTitle: 'Crime',
                bulletList: ['Total crime rate',
                'Business crime rate',
                'Number of committed crimes by type & location']
              },
              {
                groupTitle: 'Real estate',
                bulletList: [
                  'Median home value & gross rent',
                  'Home value & gross rent trend']
              },
              {
                groupTitle: 'Physical environment',
                bulletList: [
                  'Traffic intensity',
                  'Road density',
                ]
              },
              {
                groupTitle: 'Competitors and POI',
                bulletList: [
                  'Cafe & restaurants',
                  'Gas stations',
                  'C-stores',
                  'Groceries',
                  'Car washes',
                  'EV chargers',
                  'Beauty & spa facilities',
                  'Banks & ATMs',
                  'Financial services',
                  'And more'
                ]
              }
            ]
          },
        plainTextFeatureDescription: [
          'Nationwide search',
          'Up-to-date & accurate data',
          'High-resolution 5-mile and 1-mile data'
        ],
        reportTypesDescription: {
          reportsYearly: [
            {
              reportsIncludedDescription: '7 free location analysis reports',
              includedInSubscription: true,
              reportsPaidDescription: '$399 per extra report',
              sampleURL: LOCATION_INTELLIGENCE_REPORT_SAMPLE,
              isReadyToOrder: false
            },
            {
              reportsIncludedDescription: '1 free comparative location analysis report',
              includedInSubscription: true,
              reportsPaidDescription: '$499 per extra report',
              sampleURL: COMPARATIVE_LOCATION_ANALYSIS_REPORT_SAMPLE,
              isReadyToOrder: false
            }
          ],
          reportsMonthly: [
            {
              reportsPaidDescription: `7 free location analysis reports\n$399 per extra report`,
              includedInSubscription: false,
              sampleURL: LOCATION_INTELLIGENCE_REPORT_SAMPLE,
              isReadyToOrder: false

            },
            {
              reportsPaidDescription: '1 free comparative location analysis report\n$499 per extra report',
              includedInSubscription: false,
              sampleURL: COMPARATIVE_LOCATION_ANALYSIS_REPORT_SAMPLE,
              isReadyToOrder: false
            }
          ]
        }
      }
    },

    [SubscriptionPlans.FULL_ACCESS]: {
      planName: 'FULL_ACCESS',
      requestKey: SubscriptionPlans.FULL_ACCESS,
      accessibleFeatures: ALL_FEATURES_AVAILABLE,
      accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER, H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
      accessibleReportTypes: [
        ReportTypes.LOCATION_INTELLIGENCE,
        ReportTypes.COMPARATIVE_LOCATION,
        ReportTypes.FEASIBILITY_STUDY,
        ReportTypes.MAP_AI_LOCATION_OVERVIEW_FOR_BEAUTY_SALONS,
        ReportTypes.OPEN_HOUSE_FLYER,
        ReportTypes.COMMUNITY_COMPASS],
      inaccessibleFeatureText: [],
      accessibleFeaturesDescription: {
        reportTypesDescription: {},
        indicatorsDescription: {
            description: '',
            groups: []
          },
      },
      notAvailableForUser: true
    }
  }

  export const unauthorizedUser: UnauthorizedDescription = {
    accessibleFeatures: [
      PRESIDENTIAL_ELECTIONS_RESULTS2020,
      PRESIDENTIAL_ELECTIONS_RESULTS2024,
      POPULATION,
      AGE_SEX_DISTRIBUTION_CHART,
      WEALTH_INDEX_BY_COUNTRY,
      WEALTH_INDEX_BY_STATE,
      WEALTH_INDEX_CONST,
      HEALTH_INDEX_BY_COUNTRY,
      HEALTH_INDEX_BY_STATE,
      HEALTH_INDEX_CONST,
      WISE_INDEX_BY_COUNTRY,
      WISE_INDEX_BY_STATE,
      WISE_INDEX_CONST,
      MEDIAN_INCOME,
      CRIME_RATE,
      MEDIAN_GROSS_RENT,
      MEDIAN_HOUSING_SALE_COST,
      HOME_OWNERSHIP_RATIO,
      NUMBER_OF_ROOMS_CHART,
      TENURE_CHART,
      LIFE_EXPECTANCY,
      SMOKING_RATE,
      EDUCATION_ATTAINMENT_CHART,
      AIR_POLLUTION,
      COMPETITORS_POI_CHART,
      CONVENIENCE_POI_CHART,
      UNHEALTHY_BEHAVIOUR_INDEX,
      SPECIAL_HEALTH_CARE_NEEDS_INDEX,
      RACE_DISTRIBUTION_CHART,
      BACHELORS_DEGREE_OR_HIGHER_FRACTION
    ],
    accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER, H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
    actionsLimit: 50
  }

  export const trialDescription: TrialDescription = {
    planName: 'trial',
    accessibleFeatures: [
      PRESIDENTIAL_ELECTIONS_RESULTS2020,
      PRESIDENTIAL_ELECTIONS_RESULTS2024,
      BIDEN_VS_TRUMP_2020_POPULATION,
      HARRIS_VS_TRUMP_2024_POPULATION,
      POPULATION,
      AGE_SEX_DISTRIBUTION_CHART,
      WEALTH_INDEX_BY_COUNTRY,
      WEALTH_INDEX_BY_STATE,
      WEALTH_INDEX_CONST,
      HEALTH_INDEX_BY_COUNTRY,
      HEALTH_INDEX_BY_STATE,
      HEALTH_INDEX_CONST,
      WISE_INDEX_BY_COUNTRY,
      WISE_INDEX_BY_STATE,
      WISE_INDEX_CONST,
      MEDIAN_INCOME,
      CRIME_RATE,
      MEDIAN_GROSS_RENT,
      MEDIAN_HOUSING_SALE_COST,
      HOME_OWNERSHIP_RATIO,
      NUMBER_OF_ROOMS_CHART,
      TENURE_CHART,
      LIFE_EXPECTANCY,
      SMOKING_RATE,
      EDUCATION_ATTAINMENT_CHART,
      AIR_POLLUTION,
      COMPETITORS_POI_CHART,
      CONVENIENCE_POI_CHART,
      HEALTHCARE_POI_CHART,
      RETIREMENT_POI_CHART,
      FAMILY_POI_CHART,
      SCHOOLS_POI,
      UNINSURED_RATIO,
      HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
      GROSS_RENT_DISTRIBUTION_CHART,
      VACANCY_CHART,
      MEDIAN_HOUSING_SALE_COST_TREND,
      MEDIAN_GROSS_RENT_TREND,
      ORI_LAYER,
      INCOME_DISTRIBUTION_CHART,
      UNEMPLOYMENT_RATIO,
      MEDIAN_HOUSEHOLD_INCOME_TREND,
      POPULATION_TREND_CHART,
      HOUSEHOLDS,
      UNHEALTHY_BEHAVIOUR_INDEX,
      SPECIAL_HEALTH_CARE_NEEDS_INDEX,
      RACE_DISTRIBUTION_CHART,
      BACHELORS_DEGREE_OR_HIGHER_FRACTION,
      HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
      SENATE_ELECTION_2022_RESULTS
    ],
    accessibleLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER, H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER],
    accessibleFeaturesText: [],
  }
}

export class User {
  readonly data: UserPersonalData;

  constructor(data: UserPersonalData) {
    this.data = data;
  }

  public creationDate(): Date {
    return new Date(this.data.createdAt);
  }

  public fullName(): string {
    return `${this.data.firstName} ${this.data.lastName}`;
  }

  public fullNameAndCompany(): string {
    if (this.data.companyName) {
      return `${this.fullName()}, ${this.data.companyName}`;
    } else {
      return this.fullName();
    }
  }
}
