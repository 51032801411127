import { Injectable } from '@angular/core';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import { FeatureFocusService } from '../../../../feature-focus/feature-focus.service';
import {
  COUNTY_LEVEL_LAYER,
  LayerStoreService,
} from '../../../../mapbox/services/layer-store.service';
import * as mapboxgl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { NaturalId } from '../population-menu/population.service';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { LoadingService } from '../../../../mapbox/services/loading.service';
import { FeatureMetaData } from '../../../../mapbox/visualization/map-coloring.service';
import { debounceTime, of, share } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import {
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  SENATE_ELECTION_2022_RESULTS,
  voteDataFeatures,
} from '../../../../../shared/types/feature-data-type';

export type VotersDataByCells = {
  naturalId: NaturalId;
  electionResults: { [key: string]: number };
};

export type HouseOfRepresentativesElectionByCells = {
  naturalId: NaturalId;
  electionResult: {
    DEMOCRAT: number;
    REPUBLICAN: number;
    OTHER: number;
  };
};

export type SenateElectionByCells = {
  naturalId: NaturalId;
  electionResult: {
    DEMOCRAT: number;
    REPUBLICAN: number;
    OTHER: number;
  };
};

export const BIDEN_PERCENTAGE2020 = 'BIDEN_PERCENTAGE2020'
export const OTHER_PERCENTAGE2020 = 'OTHER_PERCENTAGE2020'
export const JORGENSEN_PERCENTAGE2020 = 'JORGENSEN_PERCENTAGE2020'
export const TRUMP_PERCENTAGE2020 = 'TRUMP_PERCENTAGE2020'
export const BIDEN_ABSOLUTE2020 = 'BIDEN_ABSOLUTE2020'
export const TRUMP_ABSOLUTE2020 = 'TRUMP_ABSOLUTE2020'
export const OTHER_ABSOLUTE2020 = 'OTHER_ABSOLUTE2020'
export const JORGENSEN_ABSOLUTE2020 = 'JORGENSEN_ABSOLUTE2020'

export const HARRIS_PERCENTAGE2024 = 'HARRIS_PERCENTAGE2024'
export const OTHER_PERCENTAGE2024 = 'OTHER_PERCENTAGE2024'
export const OLIVER_PERCENTAGE2024 = 'OLIVER_PERCENTAGE2024'
export const STEIN_PERCENTAGE2024 = 'STEIN_PERCENTAGE2024'
export const TRUMP_PERCENTAGE2024 = 'TRUMP_PERCENTAGE2024'
export const HARRIS_ABSOLUTE2024 = 'HARRIS_ABSOLUTE2024'
export const TRUMP_ABSOLUTE2024 = 'TRUMP_ABSOLUTE2024'
export const STEIN_ABSOLUTE2024 = 'STEIN_ABSOLUTE2024'
export const OTHER_ABSOLUTE2024 = 'OTHER_ABSOLUTE2024'
export const OLIVER_ABSOLUTE2024 = 'OLIVER_ABSOLUTE2024'

export const DEMOCRAT_HOUSE_OF_REPRESENTATIVES_PERCENTAGE = 'DEMOCRAT_HOUSE_OF_REPRESENTATIVES_PERCENTAGE'
export const REPUBLICAN_HOUSE_OF_REPRESENTATIVES_PERCENTAGE = 'REPUBLICAN_HOUSE_OF_REPRESENTATIVES_PERCENTAGE'
export const DEMOCRAT_HOUSE_OF_REPRESENTATIVES_ABSOLUTE = 'DEMOCRAT_HOUSE_OF_REPRESENTATIVES_ABSOLUTE'
export const REPUBLICAN_HOUSE_OF_REPRESENTATIVES_ABSOLUTE = 'REPUBLICAN_HOUSE_OF_REPRESENTATIVES_ABSOLUTE'
export const OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE = 'OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE'
export const OTHER_HOUSE_OF_REPRESENTATIVES_ABSOLUTE = 'OTHER_HOUSE_OF_REPRESENTATIVES_ABSOLUTE'

export const DEMOCRAT_SENATE_2022_PERCENTAGE = 'DEMOCRAT_SENATE_2022_PERCENTAGE'
export const REPUBLICAN_SENATE_2022_PERCENTAGE = 'REPUBLICAN_SENATE_2022_PERCENTAGE'
export const DEMOCRAT_SENATE_2022_ABSOLUTE = 'DEMOCRAT_SENATE_2022_ABSOLUTE'
export const REPUBLICAN_SENATE_2022_ABSOLUTE = 'REPUBLICAN_SENATE_2022_ABSOLUTE'
export const OTHER_SENATE_2022_PERCENTAGE = 'OTHER_SENATE_2022_PERCENTAGE'
export const OTHER_SENATE_2022_ABSOLUTE = 'OTHER_SENATE_2022_ABSOLUTE'

@Injectable({
  providedIn: 'root'
})
export class PoliticalLandscapeService {
  private readonly naturalIds: NaturalId[] = [];

  constructor(
    private layerStore: LayerStoreService,
    private mapService: MapBoxService,
    private http: MapHttpService,
    private loadingService: LoadingService,
    private featureFocusService: FeatureFocusService
  ) {}

  public setVotersData(layer: string): void {
    if (this.layerStore.isCurrentLevelCells() && voteDataFeatures.includes(this.layerStore.activeLayer.value)) {
      this.layerStore.handleLevelChange(COUNTY_LEVEL_LAYER)
    }

    const map = this.mapService.map;
    this.loadingService.isLoadingManual.next(true);

    this.naturalIds.length = 0;
    const naturalIdToFeatureMap: Map<string, FeatureMetaData> = new Map();
    const currentFocusedFeatures = this.featureFocusService.currentFocusedFeatures().getFeatures();

    currentFocusedFeatures.forEach((feature: MapboxGeoJSONFeature) => {
      const featureMetaData: FeatureMetaData = {
        id: feature.id,
        source: feature.source,
        sourceLayer: feature.sourceLayer
      };

      const state = map.getFeatureState(featureMetaData);

      if (
        !state.hasOwnProperty(layer) &&
        !state[`${layer}Requested`] &&
        feature.properties!.external_id
      ) {
        this.naturalIds.push(feature.properties!.external_id);
        naturalIdToFeatureMap.set(feature.properties!.external_id, featureMetaData);
      }
    });

    if (!this.naturalIds.length) {
      this.loadingService.isLoadingManual.next(false);
      return;
    }

    this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, true);
    this.featureFocusService.focusRenderedFeatures(
      map,
      this.layerStore.activeLevel.getValue()
    );

    this.http
      .getVotersData(this.naturalIds, 2020)
      .pipe(
        debounceTime(150),
        share(),
        finalize(() => {
          this.loadingService.isLoadingManual.next(false);
          this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, false);
          this.featureFocusService.focusRenderedFeatures(
            map,
            this.layerStore.activeLevel.getValue()
          );
        })
      )
      .subscribe(
        (data: JsonResponse<{ byCells: VotersDataByCells[] }>) => {
          data.object.byCells.forEach((cell: VotersDataByCells) => {
            const correspondingFeature = naturalIdToFeatureMap.get(cell.naturalId);

            if (correspondingFeature && cell.electionResults) {
              const results = this.calculateVotersData(cell);

              const newState = {
                [`${PRESIDENTIAL_ELECTIONS_RESULTS2020}Requested`]: true,
                [PRESIDENTIAL_ELECTIONS_RESULTS2020]: results.PRESIDENTIAL_ELECTIONS_RESULTS2020,
                [BIDEN_PERCENTAGE2020]: results.BIDEN_PERCENTAGE,
                [TRUMP_PERCENTAGE2020]: results.TRUMP_PERCENTAGE,
                [JORGENSEN_PERCENTAGE2020]: results.JORGENSEN_PERCENTAGE,
                [OTHER_PERCENTAGE2020]: results.OTHER_PERCENTAGE,
                [BIDEN_ABSOLUTE2020]: cell.electionResults.BIDEN,
                [TRUMP_ABSOLUTE2020]: cell.electionResults.TRUMP,
                [JORGENSEN_ABSOLUTE2020]: cell.electionResults.JORGENSEN,
                [OTHER_ABSOLUTE2020]: cell.electionResults.OTHER
              };

              map.setFeatureState(correspondingFeature, newState);
            }
          });
        },
        (error) => {
          console.error('ERROR FETCHING VOTERS DATA FOR MAP COLORING', layer, error);
          return of(null);
        }
      );

    this.http
      .getVotersData(this.naturalIds, 2024)
      .pipe(
        debounceTime(150),
        share(),
        finalize(() => {
          this.loadingService.isLoadingManual.next(false);
          this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, false);
          this.featureFocusService.focusRenderedFeatures(
            map,
            this.layerStore.activeLevel.getValue()
          );
        })
      )
      .subscribe(
        (data: JsonResponse<{ byCells: VotersDataByCells[] }>) => {
          data.object.byCells.forEach((cell: VotersDataByCells) => {
            const correspondingFeature = naturalIdToFeatureMap.get(cell.naturalId);

            if (correspondingFeature && cell.electionResults) {
              const results = this.calculateVotersData2024(cell);

              const newState = {
                [`${PRESIDENTIAL_ELECTIONS_RESULTS2024}Requested`]: true,
                [PRESIDENTIAL_ELECTIONS_RESULTS2024]: results.PRESIDENTIAL_ELECTIONS_RESULTS2024,
                [HARRIS_PERCENTAGE2024]: results.HARRIS_PERCENTAGE,
                [TRUMP_PERCENTAGE2024]: results.TRUMP_PERCENTAGE,
                [OLIVER_PERCENTAGE2024]: results.OLIVER_PERCENTAGE,
                [STEIN_PERCENTAGE2024]: results.STEIN_PERCENTAGE,
                [OTHER_PERCENTAGE2024]: results.OTHER_PERCENTAGE,
                [HARRIS_ABSOLUTE2024]: cell.electionResults.HARRIS,
                [TRUMP_ABSOLUTE2024]: cell.electionResults.TRUMP,
                [OLIVER_ABSOLUTE2024]: cell.electionResults.OLIVER,
                [STEIN_ABSOLUTE2024]: cell.electionResults.STEIN,
                [OTHER_ABSOLUTE2024]: cell.electionResults.OTHER
              };

              map.setFeatureState(correspondingFeature, newState);
            }
          });
        },
        (error) => {
          console.error('ERROR FETCHING VOTERS DATA FOR MAP COLORING', layer, error);
          return of(null);
        }
      );

    this.http
      .getHouseOfRepresentativesElectionData(this.naturalIds)
      .pipe(
        debounceTime(150),
        share(),
        finalize(() => {
          this.loadingService.isLoadingManual.next(false);
          this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, false);
          this.featureFocusService.focusRenderedFeatures(
            map,
            this.layerStore.activeLevel.getValue()
          );
        })
      )
      .subscribe(
        (data: JsonResponse<{ byCells: HouseOfRepresentativesElectionByCells[] }>) => {
          data.object.byCells.forEach((cell: HouseOfRepresentativesElectionByCells) => {
            const correspondingFeature = naturalIdToFeatureMap.get(cell.naturalId);

            if (correspondingFeature && cell.electionResult) {
              const results = this.calculateHouseOfRepresentativesVotesData(cell);

              const newState = {
                [`${HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS}Requested`]: true,
                [HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS]: results.HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
                [DEMOCRAT_HOUSE_OF_REPRESENTATIVES_PERCENTAGE]: results.DEMOCRAT_PERCENTAGE,
                [REPUBLICAN_HOUSE_OF_REPRESENTATIVES_PERCENTAGE]: results.REPUBLICAN_PERCENTAGE,
                [OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE]: results.OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE,
                [DEMOCRAT_HOUSE_OF_REPRESENTATIVES_ABSOLUTE]: cell.electionResult.DEMOCRAT,
                [REPUBLICAN_HOUSE_OF_REPRESENTATIVES_ABSOLUTE]: cell.electionResult.REPUBLICAN,
                [OTHER_HOUSE_OF_REPRESENTATIVES_ABSOLUTE]: cell.electionResult.OTHER
              };

              map.setFeatureState(correspondingFeature, newState);
            }
          });
        },
        (error) => {
          console.error('ERROR FETCHING HOUSE OF REPRESENTATIVES DATA FOR MAP COLORING', layer, error);
          return of(null);
        }
      );

    this.http
      .getSenateElectionData(this.naturalIds)
      .pipe(
        debounceTime(150),
        share(),
        finalize(() => {
          this.loadingService.isLoadingManual.next(false);
          this.markAsBeingLoaded(naturalIdToFeatureMap.values(), map, false);
          this.featureFocusService.focusRenderedFeatures(
            map,
            this.layerStore.activeLevel.getValue()
          );
        })
      )
      .subscribe(
        (data: JsonResponse<{ byCells: SenateElectionByCells[] }>) => {
          data.object.byCells.forEach((cell: SenateElectionByCells) => {
            const correspondingFeature = naturalIdToFeatureMap.get(cell.naturalId);

            if (correspondingFeature && cell.electionResult) {
              const results = this.calculateSenateVotesData(cell);

              const newState = {
                [`${SENATE_ELECTION_2022_RESULTS}Requested`]: true,
                [SENATE_ELECTION_2022_RESULTS]: results.SENATE_ELECTION_2022_RESULTS,
                [DEMOCRAT_SENATE_2022_PERCENTAGE]: results.DEMOCRAT_2022_PERCENTAGE,
                [REPUBLICAN_SENATE_2022_PERCENTAGE]: results.REPUBLICAN_2022_PERCENTAGE,
                [OTHER_SENATE_2022_PERCENTAGE]: results.OTHER_SENATE_2022_PERCENTAGE,
                [DEMOCRAT_SENATE_2022_ABSOLUTE]: cell.electionResult.DEMOCRAT,
                [REPUBLICAN_SENATE_2022_ABSOLUTE]: cell.electionResult.REPUBLICAN,
                [OTHER_SENATE_2022_ABSOLUTE]: cell.electionResult.OTHER
              };

              map.setFeatureState(correspondingFeature, newState);
            }
          });
        },
        (error) => {
          console.error('ERROR FETCHING HOUSE OF REPRESENTATIVES DATA FOR MAP COLORING', layer, error);
          return of(null);
        }
      );
  }

  private markAsBeingLoaded(
    cells: IterableIterator<FeatureMetaData>,
    map: mapboxgl.Map,
    isBeingLoaded = true
  ) {
    for (let cell of cells) {
      map.setFeatureState(cell, { isBeingLoaded: isBeingLoaded });
    }
  }

  private calculateVotersData2024(cell: VotersDataByCells): {
    PRESIDENTIAL_ELECTIONS_RESULTS2024: number;
    HARRIS_PERCENTAGE: number,
    TRUMP_PERCENTAGE: number,
    OLIVER_PERCENTAGE: number,
    OTHER_PERCENTAGE: number,
    STEIN_PERCENTAGE: number
  } {
    const totalVotes =
      (cell.electionResults.OTHER ?? 0) +
      (cell.electionResults.TRUMP ?? 0) +
      (cell.electionResults.HARRIS ?? 0) +
      (cell.electionResults.STEIN ?? 0) +
      (cell.electionResults.OLIVER ?? 0);

    if (totalVotes === 0) {
      return {
        PRESIDENTIAL_ELECTIONS_RESULTS2024: 0,
        HARRIS_PERCENTAGE: 0,
        TRUMP_PERCENTAGE: 0,
        OLIVER_PERCENTAGE: 0,
        OTHER_PERCENTAGE: 0,
        STEIN_PERCENTAGE: 0
      };
    }

    // Calculate percentages
    const harrisPercentage = (cell.electionResults.HARRIS / totalVotes) * 100;
    const trumpPercentage = (cell.electionResults.TRUMP / totalVotes) * 100;
    const oliverPercentage = (cell.electionResults.OLIVER / totalVotes) * 100;
    const steinPercentage = (cell.electionResults.STEIN / totalVotes) * 100;
    const otherPercentage = (cell.electionResults.OTHER / totalVotes) * 100

    // Calculate the difference for color intensity
    let presidentialResult = harrisPercentage - trumpPercentage;

    return {
      PRESIDENTIAL_ELECTIONS_RESULTS2024: presidentialResult,
      HARRIS_PERCENTAGE: harrisPercentage,
      TRUMP_PERCENTAGE: trumpPercentage,
      OLIVER_PERCENTAGE: oliverPercentage,
      OTHER_PERCENTAGE: otherPercentage,
      STEIN_PERCENTAGE: steinPercentage
    };
  }

  private calculateVotersData(cell: VotersDataByCells): {
    PRESIDENTIAL_ELECTIONS_RESULTS2020: number;
    BIDEN_PERCENTAGE: number,
    TRUMP_PERCENTAGE: number,
    JORGENSEN_PERCENTAGE: number,
    OTHER_PERCENTAGE: number
  } {
    const totalVotes =
      (cell.electionResults.OTHER ?? 0) +
      (cell.electionResults.TRUMP ?? 0) +
      (cell.electionResults.BIDEN ?? 0) +
      (cell.electionResults.JORGENSEN ?? 0);

    if (totalVotes === 0) {
      return {
        PRESIDENTIAL_ELECTIONS_RESULTS2020: 0,
        BIDEN_PERCENTAGE: 0,
        TRUMP_PERCENTAGE: 0,
        JORGENSEN_PERCENTAGE: 0,
        OTHER_PERCENTAGE: 0
      };
    }

    // Calculate percentages
    const bidenPercentage = (cell.electionResults.BIDEN / totalVotes) * 100;
    const trumpPercentage = (cell.electionResults.TRUMP / totalVotes) * 100;
    const jorgensenPercentage = (cell.electionResults.JORGENSEN / totalVotes) * 100;
    const otherPercentage = (cell.electionResults.OTHER / totalVotes) * 100

    // Calculate the difference for color intensity
    let presidentialResult = bidenPercentage - trumpPercentage;

    // TODO: implement similar logic
    let houseResult = 0;

    return {
      PRESIDENTIAL_ELECTIONS_RESULTS2020: presidentialResult,
      BIDEN_PERCENTAGE: bidenPercentage,
      TRUMP_PERCENTAGE: trumpPercentage,
      JORGENSEN_PERCENTAGE: jorgensenPercentage,
      OTHER_PERCENTAGE: otherPercentage
    };
  }

  private calculateHouseOfRepresentativesVotesData(cell: HouseOfRepresentativesElectionByCells): {
    HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS: number;
    DEMOCRAT_PERCENTAGE: number,
    REPUBLICAN_PERCENTAGE: number,
    OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE: number
  } {
    const DEMOCRAT = cell.electionResult.DEMOCRAT ?? 0;
    const REPUBLICAN = cell.electionResult.REPUBLICAN ?? 0;
    const OTHER = cell.electionResult.OTHER ?? 0;
    const totalVotes = DEMOCRAT + REPUBLICAN + OTHER;

    if (totalVotes === 0) {
      return {
        HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS: 0,
        DEMOCRAT_PERCENTAGE: 0,
        REPUBLICAN_PERCENTAGE: 0,
        OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE: 0
      };
    }

    // Calculate percentages
    const democratPercentage = (DEMOCRAT / totalVotes) * 100;
    const republicanPercentage = (REPUBLICAN / totalVotes) * 100;
    const otherPercentage = (OTHER / totalVotes) * 100

    // Calculate the difference for color intensity
    let houseOfRepresentativesResult = democratPercentage - republicanPercentage;

    return {
      HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS: houseOfRepresentativesResult,
      DEMOCRAT_PERCENTAGE: democratPercentage,
      REPUBLICAN_PERCENTAGE: republicanPercentage,
      OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE: otherPercentage
    };
  }

  private calculateSenateVotesData(cell: SenateElectionByCells): {
    SENATE_ELECTION_2022_RESULTS: number;
    DEMOCRAT_2022_PERCENTAGE: number,
    REPUBLICAN_2022_PERCENTAGE: number,
    OTHER_SENATE_2022_PERCENTAGE: number
  } {
    const DEMOCRAT = cell.electionResult.DEMOCRAT ?? 0;
    const REPUBLICAN = cell.electionResult.REPUBLICAN ?? 0;
    const OTHER = cell.electionResult.OTHER ?? 0;
    const totalVotes = DEMOCRAT + REPUBLICAN + OTHER;

    if (totalVotes === 0) {
      return {
        SENATE_ELECTION_2022_RESULTS: 0,
        DEMOCRAT_2022_PERCENTAGE: 0,
        REPUBLICAN_2022_PERCENTAGE: 0,
        OTHER_SENATE_2022_PERCENTAGE: 0
      };
    }

    // Calculate percentages
    const democratPercentage = (DEMOCRAT / totalVotes) * 100;
    const republicanPercentage = (REPUBLICAN / totalVotes) * 100;
    const otherPercentage = (OTHER / totalVotes) * 100

    // Calculate the difference for color intensity
    let senateResult = democratPercentage - republicanPercentage;

    return {
      SENATE_ELECTION_2022_RESULTS: senateResult,
      DEMOCRAT_2022_PERCENTAGE: democratPercentage,
      REPUBLICAN_2022_PERCENTAGE: republicanPercentage,
      OTHER_SENATE_2022_PERCENTAGE: otherPercentage
    };
  }
}
