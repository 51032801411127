import { Injectable } from '@angular/core';
import { FeatureScope, MenuGroupData } from './menu-group/menu-group.component';
import {
  AGE_SEX_DISTRIBUTION_CHART,
  AIR_POLLUTION,
  BACHELORS_DEGREE_OR_HIGHER_FRACTION,
  BEAUTY,
  BEAUTY_POI_CHART,
  BIDEN_VS_TRUMP_2020_POPULATION,
  BINGE_DRINKING_RATE,
  BUSINESS_CRIME_RATE,
  CANCER_PREVALENCE,
  COMBINED_HWW_CONST,
  COMMUNITY_CRIME_RATE,
  COMPETITORS_POI_CHART,
  CONVENIENCE_POI_CHART,
  CONVENIENCE_STORES,
  CRIME_RATE,
  CUMULATIVE_TRAFFIC_INTENSITY,
  DIABETES_PREVALENCE,
  DRUGSTORES,
  EDUCATION_ATTAINMENT_CHART,
  EMPLOYMENT_CHART,
  FAMILY_POI_CHART,
  FINANCIAL_SERVICES_POI_CHART,
  GENDER_PAY_GAP,
  GINI_INDEX,
  GROSS_RENT_DISTRIBUTION_CHART,
  HARRIS_VS_TRUMP_2024_POPULATION,
  HEALTH_INDEX_CONST,
  HEALTHCARE_POI_CHART,
  HEART_DISEASES_PREVALENCE,
  HOME_OWNERSHIP_RATIO,
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  HOUSEHOLDS,
  HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
  INCOME_DISTRIBUTION_CHART,
  INSURANCE_DISTRIBUTION_CHART,
  LIFE_EXPECTANCY,
  MEDIAN_GROSS_RENT,
  MEDIAN_GROSS_RENT_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  MEDIAN_HOUSING_SALE_COST,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_INCOME,
  NUMBER_OF_ROOMS_CHART,
  OBESITY_RATE,
  OUTDOOR_POI_CHART,
  PATIENT_TO_DENTIST_RATIO,
  PATIENT_TO_PHYSICIAN_RATIO,
  PATIENT_TO_THERAPIST_RATIO,
  PEOPLE_PER_MI2,
  POPULATION,
  POPULATION_GROWTH_TREND,
  POPULATION_TREND_CHART,
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  RACE_DISTRIBUTION_CHART,
  RETAIL,
  RETIREMENT_POI_CHART,
  ROAD_DENSITY,
  ROAD_DENSITY_CHART,
  SCHOOLS_POI,
  SENATE_ELECTION_2022_RESULTS,
  SMOKING_RATE,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  TARGET_AUDIENCE_CHART,
  TENURE_CHART,
  TRAFFIC_INTENSITY_CHART,
  UNEMPLOYMENT_RATIO,
  UNHEALTHY_BEHAVIOUR_INDEX,
  UNINSURED_RATIO,
  VACANCY_CHART,
  WEALTH_INDEX_CONST,
  WISE_INDEX_CONST,
} from '../../../../shared/types/feature-data-type';
import { HomeownershipService } from './homeownership/homeownership.service';
import { PopulationService } from './population-menu/population.service';
import { IncomeDistributionService } from './wealth-menu/income-distribution.service';
import { PoisService } from './pois/pois.service';
import { RoadDensityService } from './road-density/road-density.service';
import { PhysicalEnvironmentService } from './physical-environment/physical-environment.service';
import { TargetAudienceService } from './target-audience/target-audience.service';
import { HealthService } from './health/health.service';
import { EducationService } from './education/education.service';
import {
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  ORI_LAYER,
  pointsMinZoom,
  STATE_LEVEL_LAYER,
} from '../../../mapbox/services/layer-store.service';
import { UserAccessService } from '../../../../user/access/user-access.service';
import { SubscriptionPlans } from '../../../../user/user/user.model';

export const POPULATION_GROUP_TITLE = 'Population';
export const HOUSING_GROUP_TITLE = 'Housing'
export const WEALTH_GROUP_TITLE = 'Wealth'
export const TARGET_AUDIENCE_GROUP_TITLE = 'Target audience'
export const CRIME_GROUP_TITLE = 'Crime'
export const HEALTH_GROUP_TITLE = 'Health related'
export const PHYSICAL_ENVIRONMENT_GROUP_TITLE = 'Physical environment'
export const HWW_GROUP_TITLE = 'Area wellness'
export const ROAD_DENSITY_GROUP_TITLE = 'Road density'
export const POI_GROUP_TITLE = 'Points of interest'
export const EDUCATION_GROUP_TITLE = 'Education'
export const LIFESTYLE_RISKS_GROUP_TITLE = 'Lifestyle risks'
export const CHRONIC_DISEASES_GROUP_TITLE = 'Chronic diseases'
export const POLITICAL_LANDSCAPE_GROUP_TITLE = 'Political landscape'
export const CDC_TOOLTIP_SOURCE_TEXT = '';
export const CHR_TOOLTIP_SOURCE_TEXT = '';
export const CRIME_TOOLTIP_SOURCE_TEXT = '';

@Injectable({
  providedIn: 'root'
})
export class LayersMenuService {

  public isMenuOpened: boolean = false

  public populationGroupData: MenuGroupData = {
    title: POPULATION_GROUP_TITLE,
    featureStatus: this.populationService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'Population',
        featureConst: POPULATION,
        tooltip: {
          headerText: 'Population',
          text: "Population data provides the number of people living in a specific area."
        }
      }, {
        featureName: 'Annual population growth',
        featureConst: POPULATION_GROWTH_TREND,
        tooltip: {
          headerText: 'Annual population growth',
          text: "Population growth measures average yearly change in population size in a specific area from 2016 to 2021," +
              " expressed as a percentage increase or decrease. Source: Map AI methodology"
        }
      }, {
        featureName: 'Population density',
        featureConst: PEOPLE_PER_MI2,
        tooltip: {
          headerText: 'Population density',
          text: "Population density shows how many people live in a specific area compared to how big that area is. It is expressed as the number of people per square mile. Source: Map AI methodology"
        }
      }, {
        featureName: 'Number of households',
        featureConst: HOUSEHOLDS,
        tooltip: {
          headerText: 'Number of households',
          text: "Households data presents the count of residential units within a specific area."
        }
      }],
      charts: [{
        toggleTitle: 'Age/sex pyramid',
        chartName: 'age/sex pyramid',
        e2eName: 'age-sex-pyramid',
        plotlyChart: null,
        yearVariable: 'ageSexYear',
        featureConst: AGE_SEX_DISTRIBUTION_CHART,
        containsChartDataVariable: 'containsAgeSexInfo',
        accessibleYears: this.populationService.ageSexAccessibleYears,
        chartsByYears: this.populationService.ageSexChartDataByYear,
        tooltip: {
          headerText: 'Age/sex pyramid',
          text: 'Age & sex data reveals the age composition of people living in a specific area. It gives valuable insights into the distribution of young, middle-aged, and older males and females.'
        }
      }, {
        toggleTitle: 'Race and ethnicity',
        chartName: 'race and ethnicity',
        plotlyChart: null,
        chartsByYears: this.populationService.raceDistributionDataByYear,
        yearVariable: 'raceDistributionYear',
        accessibleYears: this.populationService.raceDistributionAccessibleYears,
        featureConst: RACE_DISTRIBUTION_CHART,
        containsChartDataVariable: 'containsRaceDistribution',
        tooltip: {
          headerText: 'Race and ethnicity',
          text: 'Discover the area\'s diversity with an overview of racial and ethnic population distribution.'
        }
      }, {
        toggleTitle: 'Population trend',
        chartName: 'population trend',
        plotlyChart: this.populationService.populationTrendChart,
        yearVariable: null,
        featureConst: POPULATION_TREND_CHART,
        containsChartDataVariable: 'containsAgeSexInfo',
        accessibleYears: null,
        chartStyle: {"height": "370px", "margin-top": "10px", "margin-bottom": "30px"},
        tooltip: {
          headerText: 'Population trend',
          text: 'Population trend is the direction of change in the total number of people living in the area over the given period.'
        }
      }, ],
      places: null
    }
  }

  public homeownershipGroupData: MenuGroupData = {
    title: HOUSING_GROUP_TITLE,
    featureStatus: this.homeownershipService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'Annual home value growth',
        featureConst: MEDIAN_HOUSING_SALE_COST_TREND,
        tooltip: {
          headerText: 'Annual home value growth',
          text: "Home value growth shows the average percentage change in median housing" +
              " sale prices within a particular area over " +
              " from 2016 to 2021. Source: Map AI methodology"
        }
      }, {
        featureName: 'Annual rent growth',
        featureConst: MEDIAN_GROSS_RENT_TREND,
        tooltip: {
          headerText: 'Annual rent growth',
          text: "Home rent growth shows the average percentage change in median" +
              " rent prices within a particular area over " +
              " from 2016 to 2021. Source: Map AI methodology"
        }
      }, {
        featureName: 'Median gross rent',
        featureConst: MEDIAN_GROSS_RENT,
        tooltip: {
          headerText: 'Median gross rent',
          text: "Median gross rent provides a snapshot of the typical cost of renting housing in that location."
        }
      }, {
        featureName: 'Median home value',
        featureConst: MEDIAN_HOUSING_SALE_COST,
        tooltip: {
          headerText: 'Median home value',
          text: "Median home value offers insight into the typical home price in that location. Source: Map AI methodology"
        }
      }, {
        featureName: 'Homeownership rate',
        featureConst: HOME_OWNERSHIP_RATIO,
        tooltip: {
          headerText: 'Homeownership rate',
          text: "The homeownership rate is the percentage of homes in an area that are occupied by owners, showing how many people own their homes instead of renting. Source: Map AI methodology"
        }
      }],
      charts: [{
        toggleTitle: 'Vacancy',
        chartName: 'vacancy',
        plotlyChart: this.homeownershipService.homeownershipVacancyChart,
        yearVariable: 'vacancyYear',
        featureConst: VACANCY_CHART,
        containsChartDataVariable: 'containsHomeownershipVacancy',
        accessibleYears: this.homeownershipService.accessibleYears,
        chartStyle: {"height": "330px"},
        tooltip: {
          headerText: 'Vacancy',
          text: 'Vacancy illustrates the count and rate of both vacant and occupied housing units in a specific area for a chosen period.'
        }
      }, {
        toggleTitle: 'Gross rent distribution',
        chartName: 'gross rent distribution',
        plotlyChart: this.homeownershipService.grossRentChart,
        yearVariable: 'grossRentYear',
        featureConst: GROSS_RENT_DISTRIBUTION_CHART,
        containsChartDataVariable: 'containsGrossRent',
        accessibleYears: this.homeownershipService.grossRentAccessibleYears,
        chartStyle: {
          "height": "400px", "width": "350px",
        },
        tooltip: {
          headerText: 'Gross rent distribution',
          text: 'Gross rent distribution chart displays the count and rate of housing units based on their gross rent prices within a specific area for a chosen period.'
        }
      }, {
        toggleTitle: 'Home value distribution',
        chartName: 'home value distribution',
        plotlyChart: this.homeownershipService.housingSellCostChart,
        yearVariable: 'housingSellCostYear',
        featureConst: HOUSING_SELL_PRICE_DISTRIBUTION_CHART,
        containsChartDataVariable: 'containsHousingSellCost',
        accessibleYears: this.homeownershipService.housingSaleCostAccessibleYears,
        chartStyle: {
          "height": "400px", "width": "350px",
        },
        tooltip: {
          headerText: 'Home value distribution',
          text: 'Home value distribution chart displays the count and rate of housing units based on their sale price within a specific area for a chosen period.'
        }
      }, {
        toggleTitle: 'Number of rooms',
        chartName: 'number of rooms',
        plotlyChart: this.homeownershipService.numberOfRoomsChart,
        yearVariable: 'numberOfRoomsYear',
        featureConst: NUMBER_OF_ROOMS_CHART,
        containsChartDataVariable: 'containsNumberOfRooms',
        accessibleYears: this.homeownershipService.accessibleYears,
        chartStyle: { "height": "330px", "width": "420px", "margin-left": "-60px" },
        tooltip: {
          headerText: 'Number of rooms',
          text: 'Number of rooms distribution chart illustrates the count and rate of housing units categorized by the number of rooms, spanning from 1-room to 9-room apartments, within a specific area for a selected time frame.'
        }
      }, {
        toggleTitle: 'Tenure',
        chartName: 'tenure',
        plotlyChart: this.homeownershipService.homeownershipTenureChart,
        yearVariable: 'tenureYear',
        featureConst: TENURE_CHART,
        containsChartDataVariable: 'containsHomeownershipTenure',
        accessibleYears: this.homeownershipService.accessibleYears,
        chartStyle: {"height": "330px"},
        tooltip: {
          headerText: 'Tenure',
          text: 'Tenure refers to the count and rate of both owner-occupied and renter-occupied housing units in a specific area for a chosen period.'
        }
      }],
      places: null
    }
  }

  public wealthGroupData: MenuGroupData = {
    title: WEALTH_GROUP_TITLE,
    featureStatus: this.wealthService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'Median household income',
        featureConst: MEDIAN_INCOME,
        tooltip: {
          headerText: 'Median household income',
          text: "Median household income in the past 12 months (in 2021 inflation-adjusted dollars) is the midpoint of all household incomes in a specific area, meaning half of the households have incomes above this level and half have incomes below it."
        }
      }, {
        featureName: 'Annual income growth',
        featureConst: MEDIAN_HOUSEHOLD_INCOME_TREND,
        tooltip: {
          headerText: 'Annual income growth',
          text: "Income growth quantifies the average yearly percentage change " +
              "in the median income for all households" +
              " within a specific area between 2016 and 2021. Source: Map AI methodology"
        }
      }, {
        featureName: 'Income Inequality Index (I3)',
        featureConst: GINI_INDEX,
        tooltip: {
          headerText: 'Income Inequality Index (I3)',
          text: "The Income Inequality Index, also known as the Gini Index, serves as a metric to evaluate income inequality within a population. It ranges from 0 to 1, with 0 indicating perfect equality (where every household has the same income) and 1 indicating perfect inequality (where one household has all the income and everyone else has none). Source: Map AI methodology"
        }
      }, {
        featureName: 'Unemployment rate',
        featureConst: UNEMPLOYMENT_RATIO,
        tooltip: {
          headerText: 'Unemployment rate',
          text: "The unemployment rate is the percentage of people in a specific area, actively seeking employment but currently without a job, providing a key indicator of the local job market's health. Source: Map AI methodology"
        }
      }, {
        featureName: 'Gender pay gap',
        featureConst: GENDER_PAY_GAP,
        tooltip: {
          headerText: 'Gender pay gap',
          text: "A look at the income gap showing the percentage difference between the median earnings of men and women working full-time. Source: Map AI methodology"
        }
      }],
      charts: [{
        toggleTitle: 'Income distribution',
        chartName: 'income distribution',
        plotlyChart: this.wealthService.incomeDistributionChart,
        yearVariable: null,
        featureConst: INCOME_DISTRIBUTION_CHART,
        containsChartDataVariable: 'containsIncomeDistribution',
        chartStyle: {
          "height": "380px", "margin-bottom": "45px", "margin-top": "20px"
        },
        accessibleYears: null,
        tooltip: {
          headerText: 'Income distribution',
          text: 'The personal and household income distribution is the measure of how income is spread between individuals and households in a specific area.'
        }
      }, {
        toggleTitle: 'Employment',
        chartName: 'employment distribution',
        plotlyChart: this.wealthService.employmentDistributionChart,
        yearVariable: null,
        featureConst: EMPLOYMENT_CHART,
        containsChartDataVariable: 'containsEmploymentDistribution',
        chartStyle: {
          "height": "360px", "margin-bottom": "-60px", "margin-left": "-60px"
        },
        accessibleYears: null,
        tooltip: {
          headerText: 'Employment',
          text: 'The employment parameter encompasses key indicators related to workforce participation within a specific region. It includes the counts of employed and unemployed individuals, the counts of labor-able and not able individuals, and military personnel, indicating the number of individuals engaged in military service within the demographic.'
        }
      }],
      places: null
    }
  }

  public targetAudienceGroupData: MenuGroupData = {
    title: TARGET_AUDIENCE_GROUP_TITLE,
    featureStatus: this.targetAudienceService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'C-stores',
        featureConst: CONVENIENCE_STORES,
        tooltip: {
          headerText: 'Target audience',
          text: "The target audience index reveals how many potential customers match your ideal profile within the total population. The index is based on a comprehensive methodology that incorporates demographic segmentation analysis and income features analysis data from multiple sources, including the US Census Bureau and specialized market research reports. Source: Map AI methodology"
        }
      }, {
        featureName: 'Pharmacy',
        featureConst: DRUGSTORES,
        tooltip: {
          headerText: 'Target audience',
          text: "The target audience index reveals how many potential customers match your ideal profile within the total population. The index is based on a comprehensive methodology that incorporates demographic segmentation analysis and income features analysis data from multiple sources, including the US Census Bureau and specialized market research reports. Source: Map AI methodology"
        }
      }, {
        featureName: 'Beauty',
        featureConst: BEAUTY,
        tooltip: {
          headerText: 'Target audience',
          text: "The target audience index reveals how many potential customers match your ideal profile within the total population. The index is based on a comprehensive methodology that incorporates demographic segmentation analysis and income features analysis data from multiple sources, including the US Census Bureau and specialized market research reports. Source: Map AI methodology"
        }
      }, {
        featureName: 'Supermarkets',
        featureConst: RETAIL,
        tooltip: {
          headerText: 'Target audience',
          text: "The target audience index reveals how many potential customers match your ideal profile within the total population. The index is based on a comprehensive methodology that incorporates demographic segmentation analysis and income features analysis data from multiple sources, including the US Census Bureau and specialized market research reports. Source: Map AI methodology"
        }
      }],
      charts: [{
        toggleTitle: 'Target audience by industries',
        chartName: 'target audience',
        plotlyChart: this.targetAudienceService.targetAudienceChart,
        yearVariable: null,
        featureConst: TARGET_AUDIENCE_CHART,
        containsChartDataVariable: 'containsTargetAudienceInfo',
        chartStyle: {'height': '430px', 'width': '400px', "margin-top": "20px", "margin-left": "-40px"},
        accessibleYears: null,
        tooltip: {
          headerText: 'Target audience',
          text: "The target audience index reveals how many potential customers match your ideal profile within the total population. The index is based on a comprehensive methodology that incorporates demographic segmentation analysis and income features analysis data from multiple sources, including the US Census Bureau and specialized market research reports. Source: Map AI methodology"
        }
      }],
      places: null
    }
  }

  public crimeGroupData: MenuGroupData = {
    title: CRIME_GROUP_TITLE,
    featureStatus: null,
    e2eName: 'crimeGroup',
    disclaimer: {
      text: 'Crime data is available only for County levels',
      visibleOnLevels: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER, STATE_LEVEL_LAYER]
    },
    data: {
      mapColor: [{
        featureName: 'Total crime rate',
        featureConst: CRIME_RATE,
        e2eName: 'crimeRate',
        tooltip: {
          headerText: 'Total crime rate',
          text: 'A snapshot of how safe or risky an area might be. It shows you the number of all types of crimes happening in that area for every 100,000 residents. ' + CRIME_TOOLTIP_SOURCE_TEXT
        }
      },
        {
          featureName: 'Community crime rate',
          featureConst: COMMUNITY_CRIME_RATE,
          e2eName: 'crimeRateCommunity',
          tooltip: {
            headerText: 'Community crime rate',
            text: 'See how safe or risky an area is for individuals. It shows the number of individual victim crimes per 100,000 residents. ' + CRIME_TOOLTIP_SOURCE_TEXT
          }
        },
        {
          featureName: 'Business crime rate',
          featureConst: BUSINESS_CRIME_RATE,
          e2eName: 'crimeRateBusiness',
          tooltip: {
            headerText: 'Business crime rate',
            text: 'Check the safety of an area for businesses. It displays the number of business victim crimes per 100,000 residents. ' + CRIME_TOOLTIP_SOURCE_TEXT
          }
        },
      ],
      charts: null,
      places: [{
        featureName: 'Police stations',
        disclaimer: {
          text: 'Police stations are visible on Map AI only when you zoom in to the level of 1 mile cells',
          visibleOnLevels: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER, COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
          visibleOnZoom: {
            min: pointsMinZoom
          }
        },
        featureConst: ORI_LAYER,
        tooltip: {
          text: 'Explore the range of crimes reported by local police in your area.',
          headerText: 'Police stations'
        }
      }]
    }
  }

  public healthGroupData: MenuGroupData = {
    title: HEALTH_GROUP_TITLE,
    featureStatus: this.healthService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'Life expectancy',
        featureConst: LIFE_EXPECTANCY,
        tooltip: {
          headerText: 'Life expectancy',
          text: "Life expectancy is a statistical measure that estimates the average number of years a person can expect to live."
        }
      }, {
        featureName: 'Uninsured rate',
        featureConst: UNINSURED_RATIO,
        tooltip: {
          headerText: 'Uninsured rate',
          text: "The uninsured rate indicates the percentage of individuals without access to healthcare insurance. Source: Map AI methodology"
        }
      },
        {
          featureName: 'Primary care physicians ratio',
          featureConst: PATIENT_TO_PHYSICIAN_RATIO,
          tooltip: {
            headerText: 'Primary care physicians ratio',
            text: 'Number of people served by one primary care physician in the area. ' + CHR_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Population-to-dentist ratio',
          featureConst: PATIENT_TO_DENTIST_RATIO,
          tooltip: {
            headerText: 'Population-to-dentist ratio',
            text: 'Number of people served by one dentist in the area. ' + CHR_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Population-to-therapist ratio',
          featureConst: PATIENT_TO_THERAPIST_RATIO,
          tooltip: {
            headerText: 'Population-to-therapist ratio',
            text: 'Number of people served by one mental health provider in the area. ' + CHR_TOOLTIP_SOURCE_TEXT
          }},
      ],
      charts: [{
        toggleTitle: 'Insurance distribution',
        chartName: 'insurance distribution',
        plotlyChart: this.healthService.insuranceDistributionChart,
        yearVariable: 'insuranceDistributionYear',
        featureConst: INSURANCE_DISTRIBUTION_CHART,
        containsChartDataVariable: 'containsInsuranceDistribution',
        chartStyle: {'height': '350px', 'width': '390px'},
        accessibleYears: this.healthService.accessibleYears,
        tooltip: {
          headerText: 'Insurance distribution',
          text: "Insurance coverage highlights the count of insured and uninsured individuals by their sex and age."
        }
      }],
      places: null
    }
  }

  public lifestyleRisksGroupData: MenuGroupData = {
    title: LIFESTYLE_RISKS_GROUP_TITLE,
    featureStatus: null,

    data: {
      mapColor: [
        {
          featureName: 'Smoking rate',
          featureConst: SMOKING_RATE,
          tooltip: {
            headerText: 'Smoking rate',
            text: 'A look at smoking habits among adults across the United States. ' + CDC_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Binge drinking rate',
          featureConst: BINGE_DRINKING_RATE,
          tooltip: {
            headerText: 'Binge drinking rate',
            text: 'A look at drinking habits among adults across the United States. ' + CDC_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Obesity rate',
          featureConst: OBESITY_RATE,
          tooltip: {
            headerText: 'Obesity rate',
            text: 'A look at nutrition habits among adults across the United States. ' + CDC_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Unhealthy habits prevalence',
          featureConst: UNHEALTHY_BEHAVIOUR_INDEX,
          tooltip: {
            headerText: 'Unhealthy habits prevalence',
            text: 'Percentage of residents engaging in unhealthy behaviors, such as smoking, physical inactivity, excessive drinking, and being overweight or obese. Source: Map AI'
          }},
      ],
      charts: null,
      places: null
    }
  }

  public chronicDiseasesGroupData: MenuGroupData = {
    title: CHRONIC_DISEASES_GROUP_TITLE,
    featureStatus: null,

    data: {
      mapColor: [
        {
          featureName: 'Diabetes prevalence',
          featureConst: DIABETES_PREVALENCE,
          tooltip: {
            headerText: 'Diabetes prevalence',
            text: 'Percentage of diagnosed diabetes cases among adults. ' + CDC_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Cancer prevalence',
          featureConst: CANCER_PREVALENCE,
          tooltip: {
            headerText: 'Cancer prevalence',
            text: 'Percentage of cancer cases (excluding skin cancer) among adults. ' + CDC_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Heart disease prevalence',
          featureConst: HEART_DISEASES_PREVALENCE,
          tooltip: {
            headerText: 'Heart disease prevalence',
            text: 'Percentage of coronary heart disease cases among adults. ' + CDC_TOOLTIP_SOURCE_TEXT
          }},
        {
          featureName: 'Regional health conditions',
          featureConst: SPECIAL_HEALTH_CARE_NEEDS_INDEX,
          tooltip: {
            headerText: 'Regional health conditions',
            text: 'A composite measure of chronic illness prevalence in the region. Source: Map AI'
          }},
      ],
      charts: null,
      places: null
    }
  }

  public physicalEnvironmentGroupData: MenuGroupData = {
    title: PHYSICAL_ENVIRONMENT_GROUP_TITLE,
    featureStatus: this.physicalEnvironmentService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'Cumulative traffic intensity',
        featureConst: CUMULATIVE_TRAFFIC_INTENSITY,
        tooltip: {
          headerText: 'Traffic intensity',
          text: "Traffic intensity measures how busy the roads are in an area by counting the number of vehicles moving daily on all the roads, including local and transit routes. Source: Ticon"
        }
      },
        {
        featureName: 'Air pollution',
        featureConst: AIR_POLLUTION,
        tooltip: {
          headerText: 'Air pollution',
          text: 'Air pollution - particulate matter measures tiny particles in the air. It\'s reported as the average daily amount of these particles in micrograms per cubic meter (PM 2.5). ' + CHR_TOOLTIP_SOURCE_TEXT
        }}],
      charts: [{
        toggleTitle: 'Traffic intensity, vehicles per day',
        chartName: 'traffic intensity',
        plotlyChart: this.physicalEnvironmentService.trafficIntensityChart,
        yearVariable: null,
        featureConst: TRAFFIC_INTENSITY_CHART,
        containsChartDataVariable: 'containsTrafficIntensity',
        chartStyle: {width: '400px', height: '250px', right: '50px', "margin-left": "-40px"},
        accessibleYears: null,
        tooltip: null
      }],
      places: null
    }
  }

  public HWWGroupData: MenuGroupData = {
    title: HWW_GROUP_TITLE,
    featureStatus: null,

    scope: {
      default: FeatureScope.BY_COUNTRY,
      scopeDependentLayers: [HEALTH_INDEX_CONST, WEALTH_INDEX_CONST, WISE_INDEX_CONST, COMBINED_HWW_CONST]
    },
    tooltip: {
      text: 'Learn more',
      routerLink: 'info/healthy-wealthy-and-wise'
    },

    data: {
      mapColor: [{
        featureName: 'Health Index',
        featureConst: HEALTH_INDEX_CONST,
        tooltip: {
          headerText: 'Health Index',
          text: "The Health Index covers everything from life expectancy and disease prevalence, to access to healthcare services and physical environment, in terms of its potential to support healthy, active lifestyles and enhance overall well-being. Source: Map AI methodology"
        }
      }, {
        featureName: 'Wealth Index',
        featureConst: WEALTH_INDEX_CONST,
        tooltip: {
          headerText: 'Wealth Index',
          text: "The Wealth Index is a set of economic indicators that provide insight into the financial well-being of a population. This includes the data on median household income, income distribution, population growth, employment status, homeownership rates, and more. Source: Map AI methodology"
        }
      }, {
        featureName: 'Wisdom Index',
        featureConst: WISE_INDEX_CONST,
        tooltip: {
          headerText: 'Wisdom Index',
          text: "The Wisdom Index is a measure of the educational level and quality of schools in a given area. This index takes into account a variety of factors, including the percentage of individuals who have completed high school or achieved higher education, as well as the ranking of schools and local colleges and universities. Source: Map AI methodology"
        }
      }, {
        featureName: 'Franklin Index',
        featureConst: COMBINED_HWW_CONST,
        tooltip: {
          headerText: 'Franklin Index',
          text: "The Healthy, Wealthy, and Wise Index is a powerful tool that provides a comprehensive snapshot of the health, wealth and educational conditions of communities in the selected area. Dubbed the \"Franklin Index,\" this combined measure honors Benjamin Franklin\'s timeless idea that being Healthy, Wealthy, and Wise are key foundations for a successful life. Source: Map AI methodology"
        }
      }],
      charts: null,
      places: null
    }
  }

  public roadDensityGroupData: MenuGroupData = {
    title: ROAD_DENSITY_GROUP_TITLE,
    featureStatus: this.roadDensityService.featureStatus,

    data: {
      mapColor: [{
        featureName: 'Road density, mi per mi²',
        featureConst: ROAD_DENSITY,
        tooltip: {
          headerText: 'Road density',
          text: "Road density is a critical metric for assessing the level of transportation infrastructure development within a specific region. It indicates how many roads there are in a certain area compared to its size and is measured as the total length of road lanes per square mile. Source: Map AI methodology"
        }
      }],
      charts: [{
        toggleTitle: 'Road density by types',
        chartName: 'road density',
        plotlyChart: this.roadDensityService.roadDensityChart,
        yearVariable: null,
        featureConst: ROAD_DENSITY_CHART,
        containsChartDataVariable: 'containsRoadDensity',
        chartStyle: {width: '400px', height: '250px', right: '50px', "margin-left": "-40px"},
        accessibleYears: null,
        tooltip: null
      }],
      places: null
    }
  }

  public POISGroupData: MenuGroupData = {
    title: POI_GROUP_TITLE,
    featureStatus: this.poiService.featureStatus,
    e2eName: 'poiGroup',
    disclaimer: {
      text: 'Points of interest are visible on Map AI only when you zoom in to the level of 1 mile cells',
      visibleOnLevels: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER, COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
      visibleOnZoom: {
        min: pointsMinZoom
      }
    },

    data: {
      mapColor: null,
      charts: null,
      places: [{
        toggleTitle: 'Competitors',
        chartName: 'competitors',
        plotlyChart: this.poiService.competitorsChart,
        e2eName: 'competitors-poi',
        yearVariable: null,
        featureConst: COMPETITORS_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Don’t guess where all your potential customers could go. See competition pie chart highlighting the presence of nearby gas stations, convenience stores, restaurants, EV chargers, and other facilities in your area.',
          headerText: 'Competitors'
        }
      }, {
        toggleTitle: 'Family',
        chartName: 'family',
        plotlyChart: this.poiService.familyChart,
        yearVariable: null,
        featureConst: FAMILY_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Prioritize your children\'s needs when choosing a home. Explore the availability and proximity of playgrounds, childcare facilities, parks, and other amenities in your potential neighborhood.',
          headerText: 'Family'
        }
      }, {
        toggleTitle: 'Retirement',
        chartName: 'retirement',
        plotlyChart: this.poiService.retirementChart,
        yearVariable: null,
        featureConst: RETIREMENT_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Explore the availability and proximity of nursing and retirement homes nearby to stay connected with your loved ones.',
          headerText: 'Retirement'
        }
      }, {
        toggleTitle: 'Convenience',
        chartName: 'convenience',
        plotlyChart: this.poiService.entertainmentAndConvenienceChart,
        yearVariable: null,
        e2eName: 'convenience-poi',
        featureConst: CONVENIENCE_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Discover the convenience of your neighborhood. Explore the availability and proximity of grocery stores, cafes, gas stations, EV chargers, fitness clubs and gyms nearby.',
          headerText: 'Convenience'
        }
      }, {
        toggleTitle: 'Healthcare',
        chartName: 'healthcare',
        plotlyChart: this.poiService.healthcareChart,
        yearVariable: null,
        e2eName: 'healthcare-poi',
        featureConst: HEALTHCARE_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Ensure there are ample healthcare facilities such as hospitals, clinics, dentists, and doctors’ offices nearby before settling into a new neighborhood.',
          headerText: 'Healthcare'
        }
      }, {
        toggleTitle: 'Beauty & Spa',
        chartName: 'beauty & spa',
        plotlyChart: this.poiService.beautyChart,
        yearVariable: null,
        featureConst: BEAUTY_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Indulge in the beauty offerings of your area with our comprehensive chart, highlighting the presence of nail studios, massage salons, skincare boutiques, lash and brow studios, and other beauty facilities.',
          headerText: 'Beauty & Spa'
        }
      }, {
        toggleTitle: 'Financial services',
        chartName: 'financial services',
        plotlyChart: this.poiService.financialServicesChart,
        yearVariable: null,
        featureConst: FINANCIAL_SERVICES_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Explore competition dynamics and traffic generators with our detailed chart, featuring banks, ATMs, insurance agencies, tax offices, and financial advisors in your area.',
          headerText: 'Financial services'
        }
      }, {
        toggleTitle: 'Outdoors',
        chartName: 'outdoors',
        plotlyChart: this.poiService.outdoorChart,
        yearVariable: null,
        featureConst: OUTDOOR_POI_CHART,
        containsChartDataVariable: 'containsPOIs',
        chartStyle: {height: '250px', width: '430px', right: '50px'},
        accessibleYears: null,
        tooltip: {
          text: 'Explore the outdoor amenities. Check the proximity of parks, bicycle routes, and walking paths to your new home.',
          headerText: 'Outdoors'
        }
      },],
    }
  }

  public educationGroupData: MenuGroupData = {
    title: EDUCATION_GROUP_TITLE,
    featureStatus: this.educationService.featureStatus,

    data: {
      mapColor: [
        {
          featureName: 'Bachelor\'s degree or higher',
          featureConst: BACHELORS_DEGREE_OR_HIGHER_FRACTION,
          tooltip: {
            headerText: 'Bachelor\'s degree or higher',
            text: "Identify areas with the highest educational attainment by looking at the percentage of the population holding a bachelor's degree or higher. Source: Map AI methodology"
          }
        }],
      charts: [{
        toggleTitle: 'Attainment',
        chartName: 'education attainment',
        plotlyChart: this.educationService.educationAttainmentChart,
        yearVariable: 'educationAttainmentYear',
        featureConst: EDUCATION_ATTAINMENT_CHART,
        containsChartDataVariable: 'containsEducationAttainment',
        accessibleYears: this.educationService.accessibleYears,
        chartStyle: {"height": "330px", "width": "420px", "margin-left": "-60px"},
        tooltip: {
          headerText: 'Education Attainment',
          text: "Education data provides insights into the proportions of individuals who have accomplished various levels of education, such as high school diplomas, college or associate degrees, bachelor's degrees, and advanced degrees."
        }
      }],
      places: [{
        featureName: 'Schools',
        featureConst: SCHOOLS_POI,
        disclaimer: {
          text: 'Schools are visible on Map AI only when you zoom in to the level of 1 mile cells',
          visibleOnLevels: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER, COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
          visibleOnZoom: {
            min: pointsMinZoom
          }
        },
        tooltip: {
          text: 'Check out the quality of public and private schools in your neighborhood with our school rankings and detailed information, including grade range, student numbers, student-teacher ratios, diversity, and other key insights.',
          headerText: 'Schools'
        }
      }]
    }
  }

  public voteDataGroupData: MenuGroupData = {
    title: POLITICAL_LANDSCAPE_GROUP_TITLE,
    featureStatus: null,
    disclaimer: {
      text: 'Political landscape data is available only for County and State levels',
      visibleOnLevels: [H3_RES5_LEVEL_LAYER, H3_RES7_LEVEL_LAYER]
    },
    data: {
      mapColor: [{
        featureName: 'Presidential elections 2020',
        featureConst: PRESIDENTIAL_ELECTIONS_RESULTS2020,
        tooltip: {
          headerText: 'Presidential elections 2020',
          text: "Explore the political landscape of your area with a look at the 2020 Presidential election results. See how your region voted and gain insights into local political preferences. Source: MIT Election Data + Science Lab"
        }
      },{
        featureName: 'Presidential elections 2024',
        featureConst: PRESIDENTIAL_ELECTIONS_RESULTS2024,
        tooltip: {
          headerText: 'Presidential elections 2024',
          text: "Explore the political landscape of your area with a look at the 2024 Presidential election results. See how your region voted and gain insights into local political preferences. Data may be incomplete. Source: MIT Election Data + Science Lab"
        }
      },
        {
          featureName: 'Presidential elections with population',
          featureConst: BIDEN_VS_TRUMP_2020_POPULATION,
          tooltip: {
            headerText: 'Presidential elections with population',
            text: 'Population within places colored by 2020 Presidential election results. Uncover how your region voted and gain valuable insights into local political preferences. Source: Map AI methodology',
          },
        },
        {
          featureName: 'Presidential elections with population',
          featureConst: HARRIS_VS_TRUMP_2024_POPULATION,
          tooltip: {
            headerText: 'Presidential elections with population',
            text: 'Population within places colored by 2024 Presidential election results. Uncover how your region voted and gain valuable insights into local political preferences. Source: Map AI methodology',
          },
        },
        {
        featureName: 'House elections margin',
        accessibleOnLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
        featureConst: HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
        tooltip: {
          headerText: 'House elections margin',
          text: "Dive into the political landscape of your area with the 2020 House of Representatives election results. Uncover how your region voted and gain valuable insights into local political preferences. Source: MIT Election Data + Science Lab"
        }
        },
        {
          featureName: 'Senate elections 2022 margin',
          accessibleOnLevels: [COUNTY_LEVEL_LAYER, STATE_LEVEL_LAYER],
          featureConst: SENATE_ELECTION_2022_RESULTS,
          tooltip: {
            headerText: 'Senate elections 2022 margin',
            text: "Discover the political landscape of your area through the 2022 Senate election results. See how your region voted and gain insights into local political preferences. Source: MIT Election Data + Science Lab."
          }
        }
      ],
      places: null,
      charts: null
    },

  }

  public readonly groupsData: MenuGroupData[] = [
    this.HWWGroupData,
    this.populationGroupData,
    this.wealthGroupData,
    this.targetAudienceGroupData,
    this.crimeGroupData,
    this.homeownershipGroupData,
    this.educationGroupData,
    this.healthGroupData,
    this.lifestyleRisksGroupData,
    this.chronicDiseasesGroupData,
    this.POISGroupData,
    this.physicalEnvironmentGroupData,
    this.roadDensityGroupData,
    this.voteDataGroupData
  ]


  constructor(
    private accessService: UserAccessService,
    private populationService: PopulationService,
    private wealthService: IncomeDistributionService,
    private poiService: PoisService,
    private roadDensityService: RoadDensityService,
    private physicalEnvironmentService: PhysicalEnvironmentService,
    private targetAudienceService: TargetAudienceService,
    private healthService: HealthService,
    private educationService: EducationService,
    private homeownershipService: HomeownershipService,
  ) {
  }

  public reassignTooltipText(groupData: MenuGroupData): void {
    // Some tooltip texts might depend on which subscription plan is active
    if (groupData.title === POI_GROUP_TITLE) {
      groupData.data.places?.forEach(el => {
        if (el.featureConst === HEALTHCARE_POI_CHART) {
          el.tooltip!.text = this.accessService.getUserPlan() === SubscriptionPlans.REALTOR || this.accessService.getUserPlan() === SubscriptionPlans.COMMUNITY_COMPASS
            ? 'Ensure there are ample healthcare facilities such as hospitals, clinics, dentists, and doctors’ offices nearby before settling into a new neighborhood.'
            : 'Explore the healthcare scene in your area through our informative chart, detailing the presence of hospitals, clinics, dentists, and doctor\'s offices.'
        }
      })
    }
  }
}
