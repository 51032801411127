import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import * as mapboxglType from 'mapbox-gl';
import { LngLatLike, MapboxGeoJSONFeature } from 'mapbox-gl';
import { CellGeoData } from './geocoder-cache';
import {
  COUNTY_LEVEL_LAYER,
  LayerStoreService,
  ORI_LAYER,
  STATE_LEVEL_LAYER,
} from './layer-store.service';
import { MapBoxService } from '../mapbox.service';
import { schoolsPoiLayers } from '../../menu/right-menu/layers-menu/education/education.service';
import { MapHttpService } from './map-http.service';
import { DatePipe } from '@angular/common';
import { BreakpointObserverService } from '../../../shared/services/breakpoint-observer.service';
import { Feature, Position } from '@turf/helpers';
import {
  DEFAULT_POPUP,
  HOVER_POPUP,
  MapPopupComponent,
  ORI_POPUP,
  PopupData,
  SCHOOLS_POPUP,
} from '../visualization/map-popup/map-popup.component';
import { SearchGeocoderService } from '../../../shared/services/search-geocoder.service';
import { SelectedCellService } from './selected-cell.service';
import { pointsLayersList } from '../../menu/right-menu/layers-menu/points.service';

export const CLICK = 'CLICK';
export const HOVER = 'HOVER'

export type PopupActionType = typeof CLICK | typeof HOVER

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  public popupTitle!: string;
  public popup: mapboxglType.Popup = new mapboxgl.Popup({
    maxWidth: '400px',
    closeButton: false,
    closeOnClick: false,
  });

  public isSeeMoreActive: boolean = false;

  constructor(
    private layerStore: LayerStoreService,
    private mapboxService: MapBoxService,
    private mapHttpService: MapHttpService,
    private datePipe: DatePipe,
    private isMobileService: BreakpointObserverService,
    private geocoderService: SearchGeocoderService,
    private selectedCellService: SelectedCellService,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {}

  public handlePopup(
    feature: MapboxGeoJSONFeature,
    coordinates: LngLatLike,
    actionType: PopupActionType
  ): void {
    if (this.popup && this.popup.isOpen()) {
      this.popup.remove();
    }

    const data: PopupData = {
      type: actionType === HOVER
        ? HOVER_POPUP
        : schoolsPoiLayers.includes(feature.layer.id)
        ? SCHOOLS_POPUP
        : feature.layer.id === ORI_LAYER
        ? ORI_POPUP
        : DEFAULT_POPUP,
      properties: { ...feature.properties, ...feature.state },
    };

    if (pointsLayersList.includes(feature.layer.id)) {
      this.handlePointsPopup(feature);
    }

    this.initPopup(coordinates);
    this.setPopupContent(data);
  }

  public initPopup(coordinates: mapboxgl.LngLatLike): void {
    const isMobile = this.isMobileService.isMobile.getValue();
    const { anchor, offset } = {
      anchor: 'center' as mapboxgl.Anchor,
      offset: [0, 0] as mapboxgl.Offset,
    };

    // Project the coordinates to pixels
    const projected = this.mapboxService.map.project(coordinates);

    // Get map container side sizes
    const mapContainer = this.mapboxService.map.getContainer();
    const mapWidth = mapContainer.offsetWidth;
    const mapHeight = mapContainer.offsetHeight;

    // Calculate percentages for x and y relative to map width and height
    const xPercentage = (projected.x / mapWidth) * 100;
    const yPercentage = (projected.y / mapHeight) * 100;

    const newPoint = {
      x: projected.x,
      y: projected.y,
    };

    let offsetX: number = 0;
    let offsetY: number = 0;

    if (isMobile) {
      this.popup = new mapboxgl.Popup({
        anchor: anchor,
        offset: offset,
        maxWidth: '400px',
        closeButton: false,
        closeOnClick: false,
      }).setLngLat(coordinates);

      this.centerPopupOnMobile(coordinates);
    } else {
      // On desktop we want to render popup slightly away from feature so it do not hide it underneath
      if (xPercentage > 50) {
        offsetX = -(mapWidth / 10);
      } else if (xPercentage < 50) {
        offsetX += mapWidth / 10;
      }

      if (yPercentage < 30) {
        offsetY = mapHeight / 4;
      }

      if (yPercentage > 30 && yPercentage < 80) {
        offsetY = -(mapHeight / 10);
      } else if (yPercentage > 80) {
        offsetY = -(mapHeight / 10);
      }

      newPoint.x += offsetX;
      newPoint.y += offsetY;

      // Unproject new point and update popup location
      const newCoord = this.mapboxService.map.unproject(
        newPoint as mapboxgl.PointLike
      );
      this.popup.setLngLat(newCoord);
    }
  }

  private centerPopupOnMobile(coordinates: LngLatLike): void {
    const mapContainer = this.mapboxService.map;
    if (mapContainer) {
      mapContainer.flyTo({
        center: coordinates,
        zoom: mapContainer.getZoom(),
      });
      this.popup.addTo(mapContainer);
    }
  }

  public handlePopupTitle(): void {
    this.popupTitle = '';

    const feature = this.selectedCellService.getSelectedFeature();

    if (feature?.properties && feature.properties.name && feature.layer.id !== COUNTY_LEVEL_LAYER) {
      this.popupTitle = feature.properties.name;
      return;
    }

    const id = feature?.id;
    const center = this.mapboxService.getFeatureCenter(feature!);
    const level = this.layerStore.activeLevel.value;

    if (!this.geocoderService.geocoderCache.getCellData(id)) {
      this.handleGeocoderRequest(id, center).then((res) => {
        this.geocoderService.geocoderCache.addCell(res.id, res.data);
        this.updateTitle(res.id, level, feature!.properties!.name);
      });
    } else {
      this.updateTitle(id, level, feature!.properties!.name);
    }
  }

  public handleOnLoadPopup(
    feature: MapboxGeoJSONFeature,
    coordinates: LngLatLike
  ): void {
    this.handlePopup(feature, this.mapboxService.getFeatureCenter(feature), CLICK);
  }

  private setPopupContent(data: PopupData): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(MapPopupComponent);
    const componentRef = componentFactory.create(this.injector);

    componentRef.instance.data = data;

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    this.popup.setDOMContent(domElem).addTo(this.mapboxService.map);

    this.popup.on('close', () => {
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });
  }

  private async handleGeocoderRequest(
    featureID: Feature['id'],
    coordinates: Position
  ): Promise<{
    id: Feature['id'];
    data: CellGeoData;
  }> {
    this.geocoderService.reverseGeocoder.query(
      `${coordinates[1]},${coordinates[0]}`
    );

    const { id, data } = await this.handleGeocoderResult(featureID);
    return { id, data };
  }

  private handleGeocoderResult(
    id: Feature['id']
  ): Promise<{ id: Feature['id']; data: CellGeoData }> {
    return new Promise((res) => {
      const resultHandler = (event: any) => {
        this.geocoderService.reverseGeocoder.off('result', resultHandler);
        res({ id: id, data: event.result });
      };

      this.geocoderService.reverseGeocoder.on('result', resultHandler);
    });
  }

  private updateTitle(id: Feature['id'], levelLayer: string, defaultName?: string): void {
    const cellData = this.geocoderService.geocoderCache.getCellData(id);

    // States and counties do have their names in properties so we do not process it here
    if (
      levelLayer === STATE_LEVEL_LAYER ||
      // levelLayer === COUNTY_LEVEL_LAYER ||
      !cellData
    ) {
      return;
    }

    let title = '';

    let place, district, stateShort, postcode, neighborhood, stateFull;

    cellData.context.forEach((el) => {
      if (el.id.includes('place')) place = el.text;
      if (el.id.includes('district')) district = el.text;
      if (el.id.includes('postcode')) postcode = el.text;
      if (el.id.includes('neighborhood')) neighborhood = el.text;
      if (el.id.includes('region')) {
        stateShort = el.short_code;
        stateFull = el.text
      }
    });

    title = cellData.text || '';
    if (place) title += `, ${place}`;
    if (stateShort) title += `, ${stateShort}`;

    if (levelLayer === COUNTY_LEVEL_LAYER && stateFull) {
      this.popupTitle = `${defaultName}, ${stateFull}`
      return;
    }


    // Fallback to coordinates if no title could be constructed
    if (!title.trim()) {
      title = `lng: ${cellData.center[0].toFixed(
        2
      )} lat: ${cellData.center[1].toFixed(2)}`;
    }

    this.popupTitle = title;
  }

  private handlePointsPopup(feature: MapboxGeoJSONFeature): void {
    this.popup.once('open', () => {
      this.assignSelectedStatus(true, feature);
    });

    this.popup.once('close', () => {
      this.assignSelectedStatus(false, feature);
    });
  }

  private assignSelectedStatus(
    status: boolean,
    feature: MapboxGeoJSONFeature
  ): void {
    this.mapboxService.map.setFeatureState(feature, { selected: status });
  }

  public downloadXlsx(): void {
    const feature = this.selectedCellService.getSelectedFeature()!;

    this.mapHttpService
      .downloadXlsx(feature.properties!.external_id)
      .subscribe((data) => {
        const currentTime = this.datePipe.transform(
          new Date(),
          'yyyy-MM-dd HH:mm:ss'
        );
        const currentLevel = this.layerStore.activeLevel.getValue();

        const blob: Blob = data as Blob;
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${currentLevel}_${
          feature.properties!.external_id
        }_${currentTime}.xlsx`;
        link.click();
      });
  }
}
