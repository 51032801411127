import {
  targetAudienceIndustries,
  trendLayers,
  writtenInStateFeatures,
} from '../../../../shared/types/feature-data-type';
import {
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  STATE_LEVEL_LAYER,
} from '../../services/layer-store.service';

export type BracketsByLayers = {
  containsLevels: boolean;
  [COUNTY_LEVEL_LAYER]?: { min?: number; max?: number }[];
  [STATE_LEVEL_LAYER]?: { min?: number; max?: number }[];
  [H3_RES5_LEVEL_LAYER]?: { min?: number; max?: number }[];
  [H3_RES7_LEVEL_LAYER]?: { min?: number; max?: number }[];
};

export class ColorScale {
  constructor(
    private colorRamp: string[],
    private brackets?: { min?: number; max?: number }[] | BracketsByLayers,
    private isTrend?: boolean,
    private colorsToShow?: number
  ) {}

  /**
   * Maps values to [[threshold]['color']] format compatible with mapbox.
   */
  public toThresholdColorArray(values: number[], activeLevel?: string): any[][] {
    const res = this.toThresholdColorArrayUnsafe(values, activeLevel);
    this.makeThresholdsStrictlyAscending(res);
    return res;
  }

  private toThresholdColorArrayUnsafe(values: number[], activeLevel?: string): any[][] {
    if (this.isTrend && this.colorsToShow) {
      return this.getTrendsColorArray(values, this.colorsToShow);
    } else if (Array.isArray(this.brackets)) {
      // Handle the case where we've defined a bracket-based color scale
      return this.getBracketsColorArray(this.brackets);
    } else if (this.brackets?.containsLevels && activeLevel) {
      return this.getBracketsByLevelColorArray(this.brackets, activeLevel)
    } else {
      return this.getCommonColorArray(values);
    }
  }

  /**
   * Moves away same values to make thresholds strictly ascending.
   * Mapbox logs errors when thresholds are not strictly ascending
   * (like 0.2 and then 0.2), and colors may be assigned incorrectly.
   * This method workarounds this problem.
   */
  private makeThresholdsStrictlyAscending(thresholds: any[][]) {
    for (let i = 1; i < thresholds.length; i++) {
      const t = thresholds[i][0];
      const lastT = thresholds[i - 1][0];
      if (lastT > t) {
        console.error(`Threshold colorscale must be ascending,
         but see ${lastT} and ${t} index ${i}  within ${thresholds}`);
      }
      // != 0 checks case when all values are nulls
      if(lastT == t && t != 0) {
        // @ts-ignore
        thresholds[i] = [t + 0.0000000001, thresholds[i][1]];
      }
    }
  }

  private getCommonColorArray(values: number[]): any[][] {
    const singleOrEqualValues = this.processSingleOrEqualValues(values);
    if (singleOrEqualValues !== null) {
      return singleOrEqualValues;
    }

    const sortedData = values.sort((a, b) => a - b).map((el) => Number(el));
    const Q1 = ColorScale.quantile(sortedData, 0.25);
    const Q3 = ColorScale.quantile(sortedData, 0.75);
    const IQR = Q3 - Q1;
    const lowerBound = Q1 - 1.5 * IQR;
    const upperBound = Q3 + 1.5 * IQR;
    const minNumber = Math.max(lowerBound, Math.min(...values));
    const maxNumber = Math.min(upperBound, Math.max(...values));

    return this.countColorMap(minNumber, maxNumber);
  }

  private processSingleOrEqualValues(values: number[]): any[][] | null {
    if (values.length === 1 || values.every(v => v === values[0])
       && values[0] != null) {
      const middleColor = Math.floor(this.colorRamp.length / 2);

      return [[values[0], this.colorRamp[middleColor]]]
    }

    return null;
  }
  private getBracketsColorArray(brackets: { min?: number; max?: number }[]): any[][] {
      // brackets is an array of { min?: number; max?: number }
      return brackets
        .map((bracket, index) => [
          this.processBracket(bracket, index)
        ])
        .flat();
  }

  private getBracketsByLevelColorArray(brackets:  BracketsByLayers, activeLevel: string): any[][] {
    const layerBrackets = brackets[activeLevel as keyof BracketsByLayers];
     return layerBrackets!
       //@ts-ignore
        .map((bracket, index) => [this.processBracket(bracket, index)])
        .flat();
  }

  private processBracket(bracket: { min?: number; max?: number }, index: number): any[] {
    return bracket.min !== undefined && bracket.max !== undefined
      ? [bracket.max, this.colorRamp[index]]
      : bracket.min! > 1000
        ? [`>${bracket.min! / 1000}K`, this.colorRamp[index]]
        : [`>${bracket.min!}`, this.colorRamp[index]];
  }

  private getTrendsColorArray(values: number[], colorsToShow: number): any[][] {
    const singleOrEqualValues = this.processSingleOrEqualValues(values);
    if (singleOrEqualValues !== null) {
      return singleOrEqualValues;
    }
    const negativeValues = Array.from(
      new Set(values.filter((value) => value < 0).sort((a, b) => a - b))
    );
    const positiveValues = Array.from(
      new Set(values.filter((value) => value > 0).sort((a, b) => a - b))
    );

    const totalValues = negativeValues.length + positiveValues.length;
    const negativeRatio = negativeValues.length / totalValues;
    const positiveRatio = positiveValues.length / totalValues;

    // Exclude the center color (white) from colorsToShow
    const totalColors = colorsToShow;
    let negativeSteps = Math.round(negativeRatio * totalColors);
    let positiveSteps = totalColors - negativeSteps;

    // Ensure at least one step for each side
    if (negativeSteps === 0 && negativeValues.length > 0) negativeSteps = 1;
    if (positiveSteps === 0 && positiveValues.length > 0) positiveSteps = 1;

    const negativeEdges = this.getTrendsThresholds(
      negativeValues,
      negativeSteps
    );
    const positiveEdges = this.getTrendsThresholds(
      positiveValues,
      positiveSteps
    );

    const { negativeColors, positiveColors } = this.getAdaptedColors(
      negativeSteps,
      positiveSteps
    );

    const colorSteps = [
      ...negativeEdges.map((edge, i) => [edge, negativeColors[i]]),
      [0, this.colorRamp[Math.floor(this.colorRamp.length / 2)]], // Center color (white)
      ...positiveEdges.map((edge, i) => [edge, positiveColors[i]]),
    ];

    return colorSteps;
  }

  private getTrendsThresholds(sortedValues: number[], steps: number): number[] {
    const len = sortedValues.length;
    const thresholds = [];
    for (let i = 1; i <= steps; i++) {
      const index = Math.round((len / (steps + 1)) * i);
      thresholds.push(sortedValues[index - 1]);
    }
    return thresholds;
  }

  private getAdaptedColors(
    numNegative: number,
    numPositive: number
  ): { negativeColors: string[]; positiveColors: string[] } {
    const totalColors = this.colorRamp.length;
    const zeroIndex = Math.floor(totalColors / 2); // Assuming white for 0 is at the center

    // Reverse the negative color ramp to assign colors correctly
    const negativeRamp = this.colorRamp.slice(0, zeroIndex).reverse();
    const negativeColors = this.selectColors(negativeRamp, numNegative);

    const positiveRamp = this.colorRamp.slice(zeroIndex + 1);
    const positiveColors = this.selectColors(positiveRamp, numPositive);

    return { negativeColors, positiveColors };
  }

  private selectColors(colorRamp: string[], num: number): string[] {
    const colors: string[] = [];
    const step = num === 1 ? 0 : (colorRamp.length - 1) / (num - 1);

    for (let i = 0; i < num; i++) {
      const index = Math.round(i * step);
      colors.push(colorRamp[index]);
    }

    return colors;
  }

  /**
   * Maps values to mapbox's 'step' expression color for given layer.
   * @param values values to build color scale by
   * @param layer layerId to color values on
   */
  public toThresholdColorExpression(values: number[], layer: string, activeLevel?: string): any[] {
    const colors = this.toThresholdColorArray(values, activeLevel).flatMap(
      (thresholdColor) => [thresholdColor[1], thresholdColor[0]]
    );

    // expression format requires color to be the last element of array
    colors.pop();


    if (
      writtenInStateFeatures.includes(layer) ||
      trendLayers.includes(layer) ||
      targetAudienceIndustries.includes(layer)
    ) {
      return ['step', ['to-number', ['feature-state', layer], 0], ...colors];
    }
    return ['step', ['to-number', ['get', layer], 0], ...colors];
  }

  private countColorMap(minNumber: number, maxNumber: number): any[][] {
    const maxColorIndex = this.colorRamp.length;
    if (Math.abs(maxNumber - minNumber) < 1e-5) {
      const middleUpperColor = this.colorRamp[Math.ceil(maxColorIndex / 2)];
      const middleLowerColor = this.colorRamp[Math.ceil(maxColorIndex / 2) - 1];
      return [[minNumber - 1e-5], [maxNumber, middleLowerColor], [maxNumber + 1e-5, middleUpperColor]];
    }

    // map to [threshold; color]
    return this.colorRamp.map((d, i) => [
      (i / maxColorIndex) * (maxNumber - minNumber) + minNumber,
      d,
    ]);
  }

  private static quantile(arr: number[], q: number): number {
    const index = q * (arr.length - 1);
    const lowerIndex = Math.floor(index);
    const fraction = index - lowerIndex;
    return arr[lowerIndex] + fraction * (arr[lowerIndex + 1] - arr[lowerIndex]);
  }
}

