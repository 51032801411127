import { Injectable } from '@angular/core';
import { ARBITRARY_CELL, LayerStoreService } from './layer-store.service';
import { geojsonToWKT } from '@terraformer/wkt';
import { MapBoxService } from '../mapbox.service';
import { SelectedCellService } from './selected-cell.service';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as mapboxgl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { LayersDataService } from '../../menu/right-menu/layers-menu/layers-data.service';
import union from '@turf/union';
import { Feature, Polygon } from '@turf/helpers';
import { WKT } from '../../../reports/order-report/choose-location/choose-location-map/radius-selection.service';
import { MapHttpService } from './map-http.service';
import { CIRCLE_HIGHLIGHT_LAYER } from '../visualization/selection-tool/selection-tool.service';
import { CLICK, PopupService } from './popup.service';
import { JsonResponse } from '../../../shared/api/backend-config';
import { DataFormatter } from './data-formatter';
import { ToastrService } from 'ngx-toastr';

export interface CellCreateResponse {
  id: string;
  geometry: {
    type: string;
    coordinates: number[][][];
  };
  createdByUser: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  notes: string | null;
  geoId: string;
}

@Injectable({
  providedIn: 'root'
})
export class ArbitraryCellService {

  private readonly arbitraryCellURL: string = environment.apiUrl + 'cell'

  public currentArbitraryFeatureId: string | null = null

  public cellCreationStatus = new BehaviorSubject('')

  constructor(
    private mapboxService: MapBoxService,
    private http: HttpClient,
    private mapHttpService: MapHttpService,
    private selectedCellService: SelectedCellService,
    private layerStore: LayerStoreService,
    private popupService: PopupService,
    private layerDataService: LayersDataService,
    private toast: ToastrService
  ) { }


  public handleArbitraryCellCreationAndIndex(circle: Feature<Polygon>): void {
    this.popupService.popup.remove()
    this.resetArbitraryCell()

    const wkt: WKT = geojsonToWKT(circle.geometry)
        .replace('POLYGON', 'MULTIPOLYGON')
        .replace(/(\(\()([^()]+)(\)\))/, '((($2)))');

    const feature = this.getUnionFeature(this.mapboxService.map)

    this.requestCreateArbitraryCell(wkt, 'main map')
      .pipe(catchError((err) => {
          this.resetArbitraryCell()

          this.toast.error('Error while fetching data for arbitrary cell popup')
          this.cellCreationStatus.next('error')
          console.error('Error while requesting CREATE arbitrary cell',err)
          return of(null)
        }
      ))
      .subscribe(data => {
       if (!data) return

      const geoId: string = data.object.geoId
      this.currentArbitraryFeatureId = geoId
      this.cellCreationStatus.next('in-progress')

      this.requestIndexArbitraryCell(geoId)
        .pipe(catchError((err) => {
          this.resetArbitraryCell()

          this.toast.error('Error while fetching data for arbitrary cell popup')
          this.cellCreationStatus.next('error')
          console.error('Error while requesting INDEX arbitrary cell',err)
          return of(null)
        }))
        .subscribe(data => {
         if (!data) return

        feature.id = geoId
        feature.properties!.external_id = geoId

        this.cellCreationStatus.next('ready')

        this.layerStore.isArbitraryCellUsed = true

        this.mapHttpService.getCellById(geoId, ARBITRARY_CELL)
          .pipe(catchError( (err) => {
            this.resetArbitraryCell()

            this.toast.error('Error while fetching data for arbitrary cell popup')
            console.error('Error while fetching data for arbitrary cell popup', err)
            this.cellCreationStatus.next('error')
            return of(null)
          }))
          .subscribe((data: JsonResponse<Feature['properties']> | null) => {
            if (!data) return
            this.handleArbitraryFeatureData(data.object, feature)
        })
      })
    })
  }

  private handleArbitraryFeatureData(data: Feature['properties'], feature: MapboxGeoJSONFeature): void {
    feature.properties = {...(feature.properties), ...DataFormatter.formatArbitraryCellProperties(data)}
    this.createArbitraryCellLayer(feature)

    // as addLayer is side effect, we have to wait until map goes idle which would meant that layer is finally added.
    this.mapboxService.map.once('idle', () => {
      const arbitraryCell = this.mapboxService.map.queryRenderedFeatures(undefined, {
        layers: [ARBITRARY_CELL]
      })[0];

      this.selectedCellService.selectCell(arbitraryCell);
      this.popupService.handlePopup(arbitraryCell, this.mapboxService.getFeatureCenter(arbitraryCell), CLICK);
      this.layerDataService.eventDispatcher('click', arbitraryCell)
    });
  }


  public getUnionFeature(map: mapboxgl.Map): MapboxGeoJSONFeature {
    const features = map.querySourceFeatures(CIRCLE_HIGHLIGHT_LAYER)

    // @ts-ignore
    const feature = features.reduce((accumulator: Feature<Polygon> , feature: Feature<Polygon>) => {
      return union(accumulator, feature);
    });

    feature.layer = features[0].layer

    return feature
  }

  public resetArbitraryCell(): void {
    this.cellCreationStatus.next('')
    this.layerStore.isArbitraryCellUsed = false

    this.removeLayers()
  }

  public handleArbitraryCellClick(event: mapboxgl.MapLayerMouseEvent): void {
    const coordinates = event.lngLat;
    const feature = event.features![0];

    this.popupService.handlePopup(feature, coordinates, CLICK)
  }

  public requestCreateArbitraryCell(wkt: string, id: string): Observable<any> {
    return this.http.post(`${this.arbitraryCellURL}`, {multipolygonWkt: wkt, name: id, notes: 'null'})
  }

  private removeLayers(): void {
    if (this.mapboxService.map.getLayer(ARBITRARY_CELL)) {
      this.mapboxService.map.removeLayer(ARBITRARY_CELL)
      this.mapboxService.map.removeSource(ARBITRARY_CELL)
    }
  }

  private requestIndexArbitraryCell(id: string): Observable<any> {
    return this.http.post(`${this.arbitraryCellURL}/index?cellId=${id}`, {})
  }

  private createArbitraryCellLayer(feature: MapboxGeoJSONFeature): void {
    if (this.mapboxService.map.getLayer(ARBITRARY_CELL)) return

    this.mapboxService.map.addLayer({
      id: ARBITRARY_CELL,
      type: 'fill',
      source: {
        type: 'geojson',
        promoteId: 'external_id',
        data: feature,
      },
      paint: {
        'fill-color': '#695dff',
        'fill-opacity': 0,
      },
    });
  }

  public getArbitraryCellFeature(): MapboxGeoJSONFeature {
    const source = this.mapboxService.map.getSource(ARBITRARY_CELL) as mapboxgl.GeoJSONSource;
    //@ts-ignore
    return source._data
  }
}
