import { Injectable } from '@angular/core';
import { AllLayers } from '../../base/layers';
import { LayerStoreService } from '../../services/layer-store.service';
import { FeatureFocusService } from '../../../feature-focus/feature-focus.service';
import { BehaviorSubject, debounceTime } from 'rxjs';
import {
  COMBINED_HWW_BY_COUNTRY,
  COMBINED_HWW_BY_STATE,
  hwwFeatures,
} from '../../../../shared/types/feature-data-type';
import { Buckets } from '../../../menu/right-menu/layers-menu/shared/util';

@Injectable({
  providedIn: 'root',
})
export class ColorscaleService {
  public colorScaleColors: any[][] = [
    [0, '#fff7fb'],
    [0, '#ece7f2'],
    [0, '#d0d1e6'],
    [0, '#a6bddb'],
    [0, '#74a9cf'],
    [0, '#3690c0'],
    [0, '#0570b0'],
    [0, '#045a8d'],
    [0, '#023858'],
  ];

  /**
   * Feature for which the colorscale above was counted.
   */
  public colorScaleForFeature: string = '';
  public cellNumHistogram!: number[];

  public isDataAbsent: boolean = true;

  public isBeingLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private readonly commonColorScale =
    AllLayers.description[AllLayers.COMMON_LAYER].colorScale!;

  private readonly genderPayGapColorScale =
    AllLayers.description[AllLayers.GENDER_PAY_GAP_COLORSCALE].colorScale!;

  private readonly votersDataColorScale =
    AllLayers.description[AllLayers.VOTERS_DATA].colorScale!;

  private readonly bidenVsTrump2020PopulationColorScale =
    AllLayers.description[AllLayers.BIDEN_VS_TRUMP_2020_POPULATION].colorScale!;

  private readonly progressiveColorScale =
    AllLayers.description[AllLayers.PROGRESSIVE_SCALE].colorScale!;

  private readonly medianIncomeColorScale =
    AllLayers.description[AllLayers.MEDIAN_INCOME].colorScale!;

  private readonly giniIndexColorScale =
    AllLayers.description[AllLayers.GINI_INDEX].colorScale!;

  private readonly trendsColorScale =
    AllLayers.description[AllLayers.TRENDS_COLORSCALE].colorScale!;

  private readonly moreNegativeColorScale =
    AllLayers.description[AllLayers.MORE_NEGATIVE_COLORSCALE].colorScale!;

  private healthIndexColorScale =
    AllLayers.description[AllLayers.HEALTH_INDEX].colorScale!;
  private wealthIndexColorScale =
    AllLayers.description[AllLayers.WEALTH_INDEX].colorScale!;
  private wiseIndexColorScale =
    AllLayers.description[AllLayers.WISE_INDEX].colorScale!;

  constructor(
    private layerStore: LayerStoreService,
    private focusedFeatures: FeatureFocusService
  ) {
    this.focusedFeatures.focusedFeatures
      .pipe(debounceTime(200))
      .subscribe((focusedFeature) => {
        const visibleFeature = this.layerStore.activeLayer.getValue();
        const values = focusedFeature.ofType(visibleFeature).values();
        const activeLevel = this.layerStore.activeLevel.value;

        const isBeingLoaded =
          focusedFeature.isBeingLoaded() ||
          focusedFeature.getFeatures().length === 0;
        this.isBeingLoaded.next(isBeingLoaded);

        // don't count colorscale on transitions
        if (isBeingLoaded) {
          return;
        }

        // TODO:refactor
        if (
          hwwFeatures.includes(visibleFeature) &&
          visibleFeature !== COMBINED_HWW_BY_STATE &&
          visibleFeature !== COMBINED_HWW_BY_COUNTRY
        ) {
          if (
            AllLayers.description[AllLayers.HEALTH_INDEX].layers.includes(
              visibleFeature
            )
          ) {
            this.colorScaleColors =
              this.healthIndexColorScale.toThresholdColorArray(
                focusedFeature.ofType(visibleFeature).values()
              );
          } else if (
            AllLayers.description[AllLayers.WEALTH_INDEX].layers.includes(
              visibleFeature
            )
          ) {
            this.colorScaleColors =
              this.wealthIndexColorScale.toThresholdColorArray(
                focusedFeature.ofType(visibleFeature).values()
              );
          } else if (
            AllLayers.description[AllLayers.WISE_INDEX].layers.includes(
              visibleFeature
            )
          ) {
            this.colorScaleColors =
              this.wiseIndexColorScale.toThresholdColorArray(
                focusedFeature.ofType(visibleFeature).values()
              );
          }
        } else if (
          AllLayers.description[AllLayers.TRENDS_COLORSCALE].layers.includes(
            visibleFeature
          )
        ) {
          this.colorScaleColors = this.trendsColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          );
        } else if (
          AllLayers.description[
            AllLayers.GENDER_PAY_GAP_COLORSCALE
          ].layers.includes(visibleFeature)
        ) {
          this.colorScaleColors =
            this.genderPayGapColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
        } else if (
          AllLayers.description[
            AllLayers.MORE_NEGATIVE_COLORSCALE
          ].layers.includes(visibleFeature)
        ) {
          this.colorScaleColors =
            this.moreNegativeColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
        } else if (
          AllLayers.description[AllLayers.PROGRESSIVE_SCALE].layers.includes(
            visibleFeature
          )
        ) {
          this.colorScaleColors =
            this.progressiveColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
        } else if (
          AllLayers.description[AllLayers.MEDIAN_INCOME].layers.includes(
            visibleFeature
          )
        ) {
          this.colorScaleColors =
            this.medianIncomeColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
        } else if (
          AllLayers.description[AllLayers.GINI_INDEX].layers.includes(
            visibleFeature
          )
        ) {
          this.colorScaleColors =
            this.giniIndexColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
        } else if (
          AllLayers.description[AllLayers.VOTERS_DATA].layers.includes(
            visibleFeature
          )
        ) {
          this.colorScaleColors =
            this.votersDataColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values()
            );
        } else if (
          AllLayers.description[
            AllLayers.BIDEN_VS_TRUMP_2020_POPULATION
          ].layers.includes(visibleFeature)
        ) {
          this.colorScaleColors =
            this.bidenVsTrump2020PopulationColorScale.toThresholdColorArray(
              focusedFeature.ofType(visibleFeature).values(),
              activeLevel
            );
        } else {
          this.colorScaleColors = this.commonColorScale.toThresholdColorArray(
            focusedFeature.ofType(visibleFeature).values()
          );
        }

        this.cellNumHistogram = this.countHistogram(values);
        this.isDataAbsent = values.every((v) => v === null);
        this.colorScaleForFeature = visibleFeature;
      });
  }

  private countHistogram(values: number[]): number[] {
    if (values.length == 0) {
      return [];
    }
    if (values.every((v) => v == null)) {
      return values.map((v) => 0);
    }

    const bucketsRanges = this.colorScaleColors.map((c) => c[0]);
    const buckets = new Buckets(bucketsRanges);
    // TODO:filter out values filtered by filters
    const histogram = buckets.countBucketsWithin(values);
    // merge last two buckets since last color on the colorscale
    // shows both last bucket and values above maximum
    const afterMaximum = histogram.pop()!;
    histogram[histogram.length - 1] += afterMaximum;
    return histogram;
  }
}
