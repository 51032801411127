import { MapModalData } from './map-redirect-modal.component';
import { pointsMinZoom } from '../../map/mapbox/services/layer-store.service';

export const ROUTER_NAVIGATE = 'ROUTER_NAVIGATE';
export const MAPBOX_JUMP_TO = 'MAPBOX_JUMP_TO'
export const MAPBOX_CHANGE_ZOOM = 'MAPBOX_CHANGE_ZOOM'

export const subscriptionRequiredText: MapModalData = {
  headerText: 'Subscription required',
  mainText: 'To see information about this area, select a',
  linkText: 'Subscription plan',
  linkURL: '/user/subscription',
  buttonText: 'To Subscriptions',
  URLtoGo: '/user/subscription',
  actionType: ROUTER_NAVIGATE,
  disableClose: false
}

export const registrationRequiredText: MapModalData = {
  headerText: 'You’ve reached todays’ limit of guest clicks',
  mainText: 'They will reset tomorrow.\n' +
    'You can also sign up for free to continue using the service now.',
  linkTextInMainText: 'sign up for free',
  hasBackdrop: false,
  linkURL: '/sign-in',
  buttonText: 'To sign in',
  URLtoGo: '/sign-in',
  actionType: ROUTER_NAVIGATE,
  disableClose: true
}

export const limitedCellsResolutionText: MapModalData = {
  headerText: 'High resolution data',
  mainText: 'Higher resolution data is not accessible for this subscription',
  buttonText: 'To Subscriptions',
  URLtoGo: '/user/subscription',
  actionType: ROUTER_NAVIGATE,
  disableClose: false
}

export const moveTrialUserToAtlantaText: MapModalData = {
  headerText: 'Limited Access',
  mainText: 'During the Trial Period, your access to high resolution data is limited to the Metro Atlanta area. High resolution data for each and every place in the USA  is accessible at the paid',
  linkText: 'subscription levels',
  linkURL: '/user/subscription',
  buttonText: 'To Atlanta',
  URLtoGo: '?ll=33.85281,-84.33517&z=10',
  actionType: MAPBOX_JUMP_TO,
  disableClose: true
}

export const trialExpiredText: MapModalData = {
  headerText: 'Map AI trial expired',
  subHeaderText: 'Your free Map AI Trial has expired!',
  mainText: 'To continue exploring Map AI features, please choose',
  linkText: 'Subscription plan',
  linkURL: '/user/subscription',
  buttonText: 'To Subscriptions',
  URLtoGo: '/user/subscription',
  actionType: ROUTER_NAVIGATE,
  disableClose: true
}

export const zoomInText: MapModalData = {
  headerText: 'Places on the map',
  mainText: 'Points of interest are visible on Map AI only when you zoom in to the level of 1 mile cells',
  buttonText: 'Zoom in',
  actionType: MAPBOX_CHANGE_ZOOM,
  jumpToZoom: pointsMinZoom
}

export const signInToAccessReports: MapModalData = {
  headerText: 'Reports',
  mainText: 'Sign in to access this page',
  buttonText: 'To sign in',
  URLtoGo: '/sign-in',
  actionType: ROUTER_NAVIGATE,
  disableClose: false,
  hasBackdrop: true
}
