import { Injectable } from '@angular/core';
import {
  COUNTY_LEVEL_LAYER,
  H3_RES5_LEVEL_LAYER,
  H3_RES7_LEVEL_LAYER,
  LayerStoreService,
  MIN_RES_7_ZOOM,
  STATE_LEVEL_LAYER,
} from '../../services/layer-store.service';
import { MapBoxService } from '../../mapbox.service';
import { LevelLayers } from './levels';

// interface SelectedLevelData {
//     level: keyof typeof LevelLayers;
//     preferredZoom: number | undefined;
// }

interface SelectedLevelData {
  level: string;  // Changed from keyof typeof LevelLayers
  preferredZoom: number | undefined;
}


@Injectable({
    providedIn: 'root',
})
export class LevelSelectionService {
  private readonly selections: { [value in LevelLayers]: SelectedLevelData } =
    {
      [LevelLayers.featured_cell_state]: {
        level: STATE_LEVEL_LAYER,
        preferredZoom: 3.05,
      },
      [LevelLayers.featured_cell_county]: {
        level: COUNTY_LEVEL_LAYER,
        preferredZoom: undefined,
      },
      [LevelLayers.featured_cell_h3_res_5]: {
        level: H3_RES5_LEVEL_LAYER, // Placeholder, will be updated based on zoom level
        preferredZoom: undefined,
      },
      [LevelLayers.featured_cell_h3_res_7]: {
        level: H3_RES7_LEVEL_LAYER, // Placeholder, will be updated based on zoom level
        preferredZoom: MIN_RES_7_ZOOM,
      },
    };


  constructor(
        private layerStore: LayerStoreService,
        private mapService: MapBoxService
    ) {}

  public changeLevelBySelection(selection: LevelLayers): void {

    // if (selection === LevelLayers.Cells) {
    //   const currentZoom = this.mapService.map.getZoom();
    //   const selectedData = this.selections[selection];
    //
    //   if (currentZoom > 9) {
    //     selectedData.level = H3_RES7_LEVEL_LAYER;
    //     selectedData.preferredZoom = 9;
    //   } else {
    //     selectedData.level = H3_RES5_LEVEL_LAYER;
    //     selectedData.preferredZoom = undefined;
    //   }
    // }


    this.layerStore.handleLevelChange(
      this.getSelectionByName(selection).level
    );

    this.layerStore.isLevelLayerSelected = true
    this.layerStore.lastSelectedLevel = this.getSelectionByName(selection).level

    //skip action if we're already zoomed closer than minimum Cells zoom
    if (this.mapService.map.getZoom() > MIN_RES_7_ZOOM && this.layerStore.activeLevel.getValue() === H3_RES7_LEVEL_LAYER) return

    this.changeZoomByLevelSelection(
      this.getSelectionByName(selection).preferredZoom
    );
  }

    private changeZoomByLevelSelection(zoom: number | undefined): void {
        //smooth transition between zoom
        if (zoom) {
            this.mapService.map.easeTo({
                duration: 1000,
                zoom: zoom,
                center: this.mapService.map.getCenter(),
            });
        }
    }

    private getSelectionByName(name: LevelLayers): SelectedLevelData {
      return this.selections[name];
    }
}
