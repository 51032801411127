import {
  EventEmitter,
  Injectable,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { LngLatLike } from 'mapbox-gl';
import {
  CellGeoData,
  GeocoderCache,
} from '../../map/mapbox/services/geocoder-cache';
import { environment } from '../../../environments/environment';
import * as MapboxGeocoderType from '@mapbox/mapbox-gl-geocoder';
import { ReportsService } from '../../reports/reports.service';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { JsonResponse } from '../api/backend-config';
import { finalize } from 'rxjs/operators';

declare let MapboxGeocoder: typeof MapboxGeocoderType;

export type GeocoderSearchHistory = {
  id: string;
  address: string;
  lng: number;
  lat: number;
};

@Injectable({
  providedIn: 'root',
})
export class SearchGeocoderService {
  public readonly geocoderCache = new GeocoderCache();

  public readonly reverseGeocoder: MapboxGeocoder = new MapboxGeocoder({
    accessToken: environment.mapbox.accessToken,
    mapboxgl: mapboxgl,
    countries: 'us',
    language: 'en',
    reverseGeocode: true,
  });

  public searchHistoryList: GeocoderSearchHistory[] = [];

  public isListVisible: boolean = false;

  public marker = new mapboxgl.Marker({ color: 'red' });

  private isSearchOpenedFirstTime = true;
  private lastSearchInput: string = '';

  private inputEventListeners!: {
    handleClick: () => void;
    handleInput: () => void;
    handleBlur: () => void;
    handleControlButtons: (event: Event) => void
  };

  private renderer: Renderer2;

  constructor(
    private reportsService: ReportsService,
    rendererFactory: RendererFactory2,
    private http: HttpClient
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public addGeocoderSearch(
    map: mapboxgl.Map,
    searchGeocoder: MapboxGeocoder,
    searchClose: EventEmitter<void>
  ): void {
    if (!map.hasControl(searchGeocoder)) {
      const position = 'top-left';
      map.addControl(searchGeocoder, position);

      searchGeocoder.on('result', (event) =>
        this.handleSearchResult(map, event)
      );
      searchGeocoder.on('clear', () =>
        this.handleSearchClear(searchGeocoder, searchClose)
      );

      this.handleInputEvents(searchGeocoder);
      this.replaceInputCloseIcon();
    }
    // autofocus input after click
    // @ts-ignore
    searchGeocoder._inputEl.focus();

    // Somehow icon replacement doesnt work on first render so here we artificially remove first geocoder and add new one
    if (this.isSearchOpenedFirstTime) {
      this.isSearchOpenedFirstTime = false;

      this.removeGeocoderSearch(map, searchGeocoder);

      setTimeout(() => {
        this.addGeocoderSearch(map, searchGeocoder, searchClose);
      }, 0);
    }
  }

  public flyToAddress(
    point: LngLatLike,
    map: mapboxgl.Map,
    zoom?: number
  ): void {
    map.flyTo({
      center: point,
      zoom: zoom ? zoom : Math.max(map.getZoom(), 12),
    });

    this.marker.setLngLat(point).addTo(map);

    this.isListVisible = false;
  }

  public removeGeocoderSearch(
    map: mapboxgl.Map,
    searchGeocoder: MapboxGeocoder
  ): void {
    searchGeocoder.off('result', this.handleSearchResult);
    searchGeocoder.off('clear', this.handleSearchClear);

    if (this.inputEventListeners) {
      const inputEl = (searchGeocoder as any)._inputEl;
      const { handleClick, handleInput, handleBlur, handleControlButtons } = this.inputEventListeners;

      inputEl.removeEventListener('click', handleClick);
      inputEl.removeEventListener('input', handleInput);
      inputEl.removeEventListener('blur', handleBlur);
      inputEl.removeEventListener('keydown', handleControlButtons)
    }

    map.removeControl(searchGeocoder);
  }

  // Replace default mapbox geocoder close icon with our own
  private replaceInputCloseIcon(): void {
    const closeIcon = document.querySelector(
      '.mapboxgl-ctrl-geocoder--icon.mapboxgl-ctrl-geocoder--icon-close'
    );

    if (closeIcon) {
      this.renderer.removeChild(closeIcon.parentNode, closeIcon);

      const imgElement = this.renderer.createElement('img');
      this.renderer.setAttribute(imgElement, 'src', 'assets/icons/Cancel.svg');
      this.renderer.setAttribute(imgElement, 'alt', 'Close');

      this.renderer.appendChild(closeIcon.parentNode, imgElement);
      this.renderer.setStyle(
        closeIcon.parentNode,
        'background-color',
        'transparent'
      );
    }
  }

  private handleInputEvents(searchGeocoder: any): void {
    const inputEl = searchGeocoder._inputEl;

    const handleClick = () => {
      if (searchGeocoder.inputString === '') {
        this.isListVisible = true;
        this.updateSearchHistoryList(searchGeocoder);
      }
    };

    const handleInput = () => {
      this.isListVisible = false;
    };

    const handleBlur = () => {
      searchGeocoder.setInput('')

      setTimeout(() => {
        const focusedElement = document.activeElement as HTMLElement;
        if (
          !focusedElement.closest('.search-history-wrapper') &&
          !focusedElement.closest('.history-search-item')
        ) {
          this.isListVisible = false;
        }
      }, 200); // Delay is needed to still handle click on history item before closing list
    };

    // Artificially set input to blank space so that it wont close upon ctrl, cmd, shift press
    const handleControlButtons = (event: Event) => {
      //@ts-ignore
      if ((event.ctrlKey || event.shiftKey || event.metaKey) && !event.key.match(/^[a-zA-Z0-9]$/)) {

        if (searchGeocoder.inputString === '') {
          searchGeocoder.setInput(' ')
        }

        return;
      }
    };

    inputEl.addEventListener('click', handleClick);
    inputEl.addEventListener('input', handleInput);
    inputEl.addEventListener('blur', handleBlur);
    inputEl.addEventListener('keydown', handleControlButtons)

    // Store references for later removal
    this.inputEventListeners = { handleClick, handleInput, handleBlur, handleControlButtons };
  }

  public removeSearchItemFromHistory(
    id: string,
    geocoder: MapboxGeocoder
  ): void {
    this.http
      .delete(environment.apiUrl + `geocoding/my?searchId=${id}`)
      .pipe(
        finalize(() => {
          this.updateSearchHistoryList(geocoder);
        })
      )
      .subscribe(),
      catchError((err) => {
        console.error('ERROR removing from search history', err);
        return of(null);
      });
  }

  private saveSearchResult(
    result: CellGeoData
  ): Observable<JsonResponse<CellGeoData>> {
    return this.http.put<JsonResponse<CellGeoData>>(
      environment.apiUrl + 'geocoding',
      {
        address: result.place_name,
        lng: result.center[0],
        lat: result.center[1],
      }
    );
  }

  private updateSearchHistoryList(geocoder: any): void {
    this.http
      .get<JsonResponse<GeocoderSearchHistory[]>>(
        environment.apiUrl + 'geocoding/my'
      )
      .subscribe((data) => {
        this.searchHistoryList = data.object;
        geocoder._inputEl.focus();
      }),
      catchError((err) => {
        console.error('ERROR fetching search history', err);
        return of(null);
      });
  }

  private handleSearchResult = (
    map: mapboxgl.Map,
    event: { result: CellGeoData }
  ): void => {
    this.marker.remove();

    this.saveSearchResult(event.result).subscribe(),
      catchError((err) => {
        console.error('ERROR saving search result', err);
        return of(null);
      });

    this.marker.setLngLat(event.result.center).addTo(map);
  };

  private handleSearchClear = (
    searchGeocoder: MapboxGeocoder,
    searchClose: EventEmitter<void>
  ): void => {
    // Clear event is being fired twice, so we needed to workaround with separate string variable that would store input

    this.marker.remove();
    this.isListVisible = false;

    // @ts-ignore
    const inputString = searchGeocoder.inputString.trim();

    // If there was no string in input or we cleared it in first clear trigger, do check of lastSearchInput
    if (!inputString || inputString.length === 0) {
      if (!this.lastSearchInput && this.lastSearchInput.length === 0) {
        //If we got to this part, it means that there was no value in input so we close input
        this.lastSearchInput = '';
        searchClose.emit();
      } else {
        // If lastSearchInput had a value it means that initially input contained value so we simply clear it
        // Input is being cleared by default handler
        this.lastSearchInput = inputString;
      }
    } else {
      // If there IS string in input we update stored input value to check it on second fire and clear input itself
      this.lastSearchInput = inputString;
      // @ts-ignore
      searchGeocoder.inputString = '';
    }
  };

  public queryReverseGeocoder(coordinates: mapboxgl.LngLat): void {
    this.handleGeocoderRequest(coordinates).then((res) => {
      // If we'll need more info on address like postal code, number of building etc. we can use res.place_name
      this.reportsService.selectedLocation = res.text;
    });
  }

  private handleGeocoderResult(): Promise<CellGeoData> {
    return new Promise((res) => {
      const resultHandler = (event: any) => {
        this.reverseGeocoder.off('result', resultHandler);
        res(event.result);
      };

      this.reverseGeocoder.on('result', resultHandler);
    });
  }

  private async handleGeocoderRequest(
    coordinates: mapboxgl.LngLat
  ): Promise<CellGeoData> {
    this.reverseGeocoder.query(`${coordinates.lat},${coordinates.lng}`);

    return await this.handleGeocoderResult();
  }
}
