import { Component, Input, OnInit } from '@angular/core';
import {
  BIDEN_VS_TRUMP_2020_POPULATION,
  HARRIS_VS_TRUMP_2024_POPULATION,
  HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS,
  POPULATION,
  PRESIDENTIAL_ELECTIONS_RESULTS2020,
  PRESIDENTIAL_ELECTIONS_RESULTS2024,
  SENATE_ELECTION_2022_RESULTS,
} from '../../../../../../shared/types/feature-data-type';
import {
} from '../../../../../menu/right-menu/layers-menu/political-landscape/political-landscape.service';

@Component({
  selector: 'app-voters-data-list',
  templateUrl: './voters-data-list.component.html',
  styleUrls: ['./voters-data-list.component.scss'],
})
export class VotersDataListComponent implements OnInit {
  @Input() properties!: { [key: string]: any };
  @Input() layer!:
    | typeof PRESIDENTIAL_ELECTIONS_RESULTS2024
    | typeof PRESIDENTIAL_ELECTIONS_RESULTS2020
    | typeof BIDEN_VS_TRUMP_2020_POPULATION
    | typeof HARRIS_VS_TRUMP_2024_POPULATION
    | typeof HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS
    | typeof SENATE_ELECTION_2022_RESULTS;

  totalVotes2020: number = 0;
  populationVotedPercentage2020: number = 0;
  winnerCandidate2020: string = '';
  winnerPercentage2020: number | '' = 0;
  totalVotes2024: number = 0;
  populationVotedPercentage2024: number = 0;
  winnerCandidate2024: string = '';
  winnerPercentage2024: number | '' = 0;

  constructor() {}

  ngOnInit(): void {
    this.calculateTotalVotes();
    this.calculatePopulationVotedPercentage();
    this.determineWinnerCandidate();
  }

  private calculateTotalVotes(): void {
    this.totalVotes2020 = 0;
    this.totalVotes2024 = 0;

    const props = this.properties;

    // Sum the absolute votes of all candidates
    this.totalVotes2020 =
      props.BIDEN_ABSOLUTE2020 ??
      0 + props.TRUMP_ABSOLUTE2020 ??
      0 + props.JORGENSEN_ABSOLUTE2020 ??
      0 + props.OTHER_ABSOLUTE2020 ??
      0;

    this.totalVotes2024 =
      props.HARRIS_ABSOLUTE2024 ??
      0 + props.OLIVER_ABSOLUTE2024 ??
      0 + props.STEIN_ABSOLUTE2024 ??
      0 + props.OTHER_ABSOLUTE2024 ??
      0 + props.TRUMP_ABSOLUTE2024 ??
      0;
  }

  private calculatePopulationVotedPercentage(): void {
    const population2020 = Math.abs(
      this.properties[BIDEN_VS_TRUMP_2020_POPULATION]
    );
    const population2024 = Math.abs(
      this.properties[HARRIS_VS_TRUMP_2024_POPULATION]
    );

    if (population2020 && this.totalVotes2020 > 0) {
      this.populationVotedPercentage2020 =
        (this.totalVotes2020 / population2020) * 100;
    } else {
      this.populationVotedPercentage2020 = 0;
    }

    if (population2024 && this.totalVotes2024 > 0) {
      this.populationVotedPercentage2024 =
        (this.totalVotes2024 / population2024) * 100;
    } else {
      this.populationVotedPercentage2024 = 0;
    }
  }

  private determineWinnerCandidate(): void {
    // Create an array of candidates with their names and absolute votes
    const candidates2020 = [
      {
        name: 'Biden',
        votes: this.properties.BIDEN_ABSOLUTE2020 ?? 0,
        percentage: this.properties.BIDEN_PERCENTAGE2020 ?? 0,
      },
      {
        name: 'Trump',
        votes: this.properties.TRUMP_ABSOLUTE2020 ?? 0,
        percentage: this.properties.TRUMP_PERCENTAGE2020 ?? 0,
      },
      {
        name: 'Jorgensen',
        votes: this.properties.JORGENSEN_ABSOLUTE2020 ?? 0,
        percentage: this.properties.JORGENSEN_PERCENTAGE2020 ?? 0,
      },
      {
        name: 'Other',
        votes: this.properties.OTHER_ABSOLUTE2020 ?? 0,
        percentage: this.properties.OTHER_PERCENTAGE2020 ?? 0,
      },
    ];

    const validCandidates2020 = candidates2020.filter(
      (candidate) => candidate.votes > 0
    );

    if (validCandidates2020.length > 0) {
      // Determine the candidate with the highest votes
      const winner = validCandidates2020.reduce((prev, current) => {
        return current.votes > prev.votes ? current : prev;
      });

      this.winnerCandidate2020 = winner.name;
      this.winnerPercentage2020 = winner.percentage;
    } else {
      this.winnerCandidate2020 = 'N/A';
      this.winnerPercentage2020 = '';
    }

    const candidates2024 = [
      {
        name: 'Harris',
        votes: this.properties.HARRIS_ABSOLUTE2024 ?? 0,
        percentage: this.properties.HARRIS_PERCENTAGE2024 ?? 0,
      },
      {
        name: 'Trump',
        votes: this.properties.TRUMP_ABSOLUTE2024 ?? 0,
        percentage: this.properties.TRUMP_PERCENTAGE2024 ?? 0,
      },
      {
        name: 'Oliver',
        votes: this.properties.OLIVER_ABSOLUTE2024 ?? 0,
        percentage: this.properties.OLIVER_PERCENTAGE2024 ?? 0,
      },
      {
        name: 'Stein',
        votes: this.properties.STEIN_ABSOLUTE2024 ?? 0,
        percentage: this.properties.STEIN_PERCENTAGE2024 ?? 0,
      },
      {
        name: 'Other',
        votes: this.properties.OTHER_ABSOLUTE2024 ?? 0,
        percentage: this.properties.OTHER_PERCENTAGE2024 ?? 0,
      },
    ];

    const validCandidates2024 = candidates2024.filter(
      (candidate) => candidate.votes > 0
    );

    if (validCandidates2024.length > 0) {
      // Determine the candidate with the highest votes
      const winner = validCandidates2024.reduce((prev, current) => {
        return current.votes > prev.votes ? current : prev;
      });

      this.winnerCandidate2024 = winner.name;
      this.winnerPercentage2024 = winner.percentage;
    } else {
      this.winnerCandidate2024 = 'N/A';
      this.winnerPercentage2024 = '';
    }
  }

  protected readonly BIDEN_VS_TRUMP_2020_POPULATION =
    BIDEN_VS_TRUMP_2020_POPULATION;
  protected readonly HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS =
    HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS;
  protected readonly POPULATION = POPULATION;
  protected readonly Math = Math;
  protected readonly PRESIDENTIAL_ELECTIONS_RESULTS2020 =
    PRESIDENTIAL_ELECTIONS_RESULTS2020;
  protected readonly PRESIDENTIAL_ELECTIONS_RESULTS2024 =
    PRESIDENTIAL_ELECTIONS_RESULTS2024;
  protected readonly HARRIS_VS_TRUMP_2024_POPULATION =
    HARRIS_VS_TRUMP_2024_POPULATION;
  protected readonly SENATE_ELECTION_2022_RESULTS =
    SENATE_ELECTION_2022_RESULTS;
}
