import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationMenuComponent } from './components/navigation-menu/navigation-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { DropdownMenuComponent } from './components/dropdown-menu/dropdown-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumberFormatterPipe } from './pipes/number-formatter.pipe';
import { RouterModule } from '@angular/router';
import { SwitchToPortraitComponent } from './components/switch-to-portrait-modal/switch-to-portrait.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SwitchToDesktopModalComponent } from './components/switch-to-desktop-modal/switch-to-desktop-modal.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { ObjectToKeyValuePipe } from './pipes/object-to-key-value.pipe';
import { FavoriteIconComponent } from './components/favorite-icon/favorite-icon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SplitLevelTextPipe } from './pipes/split-level-text.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';

//prettier-ignore
@NgModule({
  declarations: [
    NavigationMenuComponent,
    DropdownMenuComponent,
    NumberFormatterPipe,
    EnumToArrayPipe,
    SwitchToPortraitComponent,
    SwitchToDesktopModalComponent,
    ObjectToKeyValuePipe,
    FavoriteIconComponent,
    CarouselComponent,
    SplitLevelTextPipe,
  ],
  exports: [
    NavigationMenuComponent,
    DropdownMenuComponent,
    NumberFormatterPipe,
    EnumToArrayPipe,
    ObjectToKeyValuePipe,
    FavoriteIconComponent,
    CarouselComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    NotFoundComponent,
    NgOptimizedImage,
    MatTooltipModule,
  ],
})
export class SharedModule {}
