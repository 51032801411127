import { Injectable } from '@angular/core';
import { Plotly } from 'angular-plotly.js/lib/plotly.interface';
import { FeatureStatus } from '../population-menu/population.service';
import { MapHttpService } from '../../../../mapbox/services/map-http.service';
import { JsonResponse } from '../../../../../shared/api/backend-config';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import { Expression, MapboxGeoJSONFeature } from 'mapbox-gl';
import { MapBoxService } from '../../../../mapbox/mapbox.service';
import { SCHOOLS_POI } from '../../../../../shared/types/feature-data-type';
import {
  pointsMinZoom,
  PRIVATE_SCHOOLS_LAYER,
  PUBLIC_SCHOOLS_LAYER,
} from '../../../../mapbox/services/layer-store.service';
import { CLICK, PopupService } from '../../../../mapbox/services/popup.service';
import { zoomInText } from '../../../../../user/map-redirect-modal/map-redirect-modal-text';
import { ModalService } from '../../../../../shared/services/modal.service';
import { GUIDE_COMPLETED } from '../../../../popup-hint/popup-hint.component';

const LESS_THAN_HIGH_SCHOOL = 'Less than<br>high school<br>graduate';
const HIGH_SCHOOL_GRADUATE = "High school<br>graduate<br>(includes<br>equivalency)";
const COLLEGE_OR_ASSOCIATE = "Some college<br>or associate's<br>degree";
const BACHELOR = "Bachelor's<br>degree";
const GRADUATE_OR_PROFESSIONAL = "Graduate or<br>professional<br>degree";

const SCHOOLS_POI_GRADES = SCHOOLS_POI + '_GRADES'

export const GRADES_OFFERED_LOWEST = 'gradesOfferedLowest';
export const GRADES_OFFERED_HIGHEST = 'gradesOfferedHighest';
export const TOTAL_STUDENTS_ALL_GRADES = 'totalStudentsAllGrades';
export const PHYSICAL_ADDRESS = 'physicalAddress';
export const SCHOOL_PHONE_NUMBER = 'schoolPhoneNumber';
export const SCHOOL_TYPE_DESCRIPTION = 'schoolTypeDescription';
export const WHETHER_CHARTER_SCHOOL = 'whetherCharterSchool';
export const MAGNET_SCHOOL_INDICATOR = 'magnetSchoolIndicator';
export const STUDENT_TEACHER_RATIO = 'studentTeacherRatio';
export const PREKINDERGARTEN_STUDENTS = 'prekindergartenStudents';
export const KINDERGARTEN_STUDENTS = 'kindergartenStudents';
export const RACE_AMERICAN_INDIAN = 'raceAmericanIndian';
export const RACE_ASIAN = 'raceAsian';
export const RACE_BLACK = 'raceBlack';
export const RACE_HISPANIC = 'raceHispanic';
export const RACE_NATIVE_HAWAIIAN = 'raceNativeHawaiian';
export const RACE_TWO_OR_MORE_RACES = 'raceTwoOrMoreRaces';
export const RACE_WHITE = 'raceWhite';
export const SCHOOL_GRADE_SCALE = 'school_grade_scale'
export const SCHOOL_GRADE_NUMERIC = 'school_grade'

export interface SchoolsPoiProperties {
  diversityGrade?: number
  gradesOfferedHighest?: string
  gradesOfferedLowest?: string
  physicalAddress?: string
  raceAmericanIndian?: number
  raceAsian?: number
  raceBlack?: number
  magnetSchoolIndicator?: string
  prekindergartenStudents?: string
  raceHispanic?: number
  raceNativeHawaiian?: number
  raceTwoOrMoreRaces?: number
  raceWhite?: number
  school_grade: number
  school_grade_scale?: string
  schoolId?: string
  schoolName?: string
  schoolPhoneNumber?: string
  schoolTypeDescription?: string
  source?: string
  studentTeacherRatio?: number
  teacherGrade?: number
  totalFemaleEnrollment?: number
  totalMaleEnrollment?: number
  totalStudentsAllGrades?: number
  whetherCharterSchool?: string
  type?: string
  publicSchoolName?: string
}

export interface SchoolsPoisData {
  schools: SchoolsPoiProperties[]
}

export interface EducationFeatureStatus extends FeatureStatus {
  educationAttainmentYear: number
  containsEducationAttainment: boolean
  naturalId: string
}

export interface EducationDistributionJSON {
  distribution: EducationDistribution[]
}

export interface EducationDistribution {
  education: string,
  population: number
}

export const schoolsPoiLayers = [
  PUBLIC_SCHOOLS_LAYER,
  PRIVATE_SCHOOLS_LAYER
]

export type SchoolId = string

@Injectable({
  providedIn: 'root'
})
export class EducationService {
  public accessibleYears: number[] = [
    2010, 2011, 2012, 2013, 2014, 2015,
    2016, 2017, 2018, 2019, 2020, 2021, 2022
  ];

  private static schoolGradesColorRamp = [
    ['A+', '#66948A'],
    ['A', '#9BCF88'],
    ['A-', '#C8EFB1'],
    ['B+', '#E5F387'],
    ['B', '#F3D979'],
    ['B-', '#F2B97C'],
    ['C+', '#E68260'],
    ['C', '#CB5346'],
    ['C-', '#A23428'],
    ['D+', '#FF0000'],
    ['D', '#FF0000'],
    ['D-', '#FF0000'],
  ];

  public isSchoolsPoiToggleActive: boolean = false

  public featureStatus: EducationFeatureStatus = {
    naturalId: '',
    isSelected: false,
    containsEducationAttainment: false,
    educationAttainmentYear: 2022,
  }

  public educationAttainmentChart: {
    data: Plotly.Data,
    layout: Plotly.Layout
  } = {
    data: [],
    layout: {}
  }

  private wasEducationZoomInModalShown: boolean = false

  constructor(private http: MapHttpService,
              private mapboxService: MapBoxService,
              private popupService: PopupService,
              private modalService: ModalService) {
  }

  public fillChartsData(feature: MapboxGeoJSONFeature): void {
    const naturalId = feature.properties!.external_id

    forkJoin([this.getEducationAttainmentByCell(naturalId), this.getEducationAttainmentByUSA()])
      .subscribe(([dataByCell, dataByUSA]) => {
        if (dataByCell !== null && dataByUSA !== null) {
          this.featureStatus.isSelected = true
          this.featureStatus.naturalId = naturalId

          this.fillEducationAttainmentChart(dataByCell, dataByUSA)
        }
      })
  }

  public handleSchoolClick(feature: MapboxGeoJSONFeature, coordinates: mapboxgl.LngLat): void {
    const schoolId = feature.properties!.ncessch ?? feature.properties!.ppin

    if (feature.properties!.length > 8) return

    this.http.getSchoolsPoisData([schoolId])
      .subscribe((data: JsonResponse<SchoolsPoisData>) => {

        feature.properties = {...feature.properties, ...data.object.schools[0]}

        this.popupService.handlePopup(feature, coordinates, CLICK)
      })
  }

  public getEducationAttainmentByCell(naturalId: string): Observable<EducationDistributionJSON | null> {
    return this.http.getEducationAttainmentData(naturalId, this.featureStatus.educationAttainmentYear)
      .pipe(
        map((data: JsonResponse<EducationDistributionJSON>) => {
          return data.object
        }),
        catchError((error) => {
          this.featureStatus.containsEducationAttainment = false
          this.featureStatus.naturalId = naturalId
          this.featureStatus.isSelected = true
          console.error('ERROR FETCHING EDUCATION ATTAINMENT BY CELL', error)
          return of(null);
        }))
  }

  public getEducationAttainmentByUSA(): Observable<EducationDistributionJSON | null> {
    return this.http.getEducationAttainmentData('usa', this.featureStatus.educationAttainmentYear)
      .pipe(
        map((data: JsonResponse<EducationDistributionJSON>) => {
          return data.object

        }),
        catchError((error) => {
          this.featureStatus.containsEducationAttainment = false
          this.featureStatus.naturalId = 'USA'
          this.featureStatus.isSelected = true
          console.error('ERROR FETCHING EDUCATION ATTAINMENT BY USA', error)
          return of(null);
        }))
  }

  public static getColorForGrade(grade: string): string {
    const matchingGrade = EducationService.schoolGradesColorRamp.find(entry => entry[0] === grade);
    return matchingGrade ? matchingGrade[1] : ''
  }

  private hideSchoolsPoiLayer(): void {
    this.mapboxService.map.setLayoutProperty(PRIVATE_SCHOOLS_LAYER, 'visibility', 'none')
    this.mapboxService.map.setLayoutProperty(PUBLIC_SCHOOLS_LAYER, 'visibility', 'none')
    this.mapboxService.map.setLayoutProperty(SCHOOLS_POI_GRADES + PUBLIC_SCHOOLS_LAYER, 'visibility', 'none')
    this.mapboxService.map.setLayoutProperty(SCHOOLS_POI_GRADES + PRIVATE_SCHOOLS_LAYER, 'visibility', 'none')
  }

  private setSchoolsPoiLayerVisible(): void {
    if (!this.wasEducationZoomInModalShown  && (this.mapboxService.map.getZoom() <= pointsMinZoom && localStorage.getItem(GUIDE_COMPLETED))) {
      this.modalService.openModal(zoomInText)
      this.wasEducationZoomInModalShown = true
    }
    this.mapboxService.map.setLayoutProperty(PRIVATE_SCHOOLS_LAYER, 'visibility', 'visible')
    this.mapboxService.map.setLayoutProperty(PUBLIC_SCHOOLS_LAYER, 'visibility', 'visible')
    this.mapboxService.map.setLayoutProperty(SCHOOLS_POI_GRADES + PUBLIC_SCHOOLS_LAYER, 'visibility', 'visible')
    this.mapboxService.map.setLayoutProperty(SCHOOLS_POI_GRADES + PRIVATE_SCHOOLS_LAYER, 'visibility', 'visible')
  }

  // used by on toggle from template, don't delete
  public setPointsLayerVisibility(checked: boolean): void {
    this.isSchoolsPoiToggleActive = checked
    if (checked) {
      this.setSchoolsPoiLayerVisible()
    } else {
      this.hideSchoolsPoiLayer()
    }
  }

  public addSchoolsLayers(layer: string, minzoom: number): void {
    this.mapboxService.map.addLayer({
      id: layer,
      type: 'circle',
      source: layer,
      'source-layer': layer,
      minzoom: minzoom,
      layout: {
        visibility: 'none'
      },
      "paint": {
        "circle-radius": [
          'interpolate',
          ['linear'],
          ['zoom'],
          9, [
            'case',
            ['==', ['feature-state', 'selected'], true],
            ['*', 3, 2], // If selected, double the radius (3 * 2)
            3 // Otherwise, use a radius of 3
          ],
          15, [
            'case',
            ['==', ['feature-state', 'selected'], true],
            ['*', 15, 2], // If selected, double the radius (15 * 2)
            15 // Otherwise, use a radius of 15
          ]
        ],
        "circle-opacity": [
          'case',
          ['==', ['feature-state', 'selected'], true],
          1,
          0.7
        ],
        'circle-color': EducationService.generateCircleColorExpression()
      },
    })

    this.mapboxService.map.addLayer({
      'id': SCHOOLS_POI_GRADES + layer,
      type: 'symbol',
      source: layer,
      "source-layer": layer,
      minzoom: minzoom,
      layout: {
        visibility: 'none',
        'text-field': [
          'coalesce',
          ['get', SCHOOL_GRADE_SCALE],
          'N/A'
        ],
        'text-size': 16,
      },
      paint: {
        'text-color': 'black'
      }
    })
  }

  public static generateCircleColorExpression(): Expression {
    // Start with the 'case' part of the expression
    let expression: Expression = ['case'];

    // Add stop for each grade in the ramp
    this.schoolGradesColorRamp.forEach(([grade, color]) => {
      expression.push(['==', ['get', SCHOOL_GRADE_SCALE], grade], color);
    });

    // Add a default color if no conditions are met
    expression.push('#ccc');

    return expression;
  }

  private fillEducationAttainmentChart(educationDataByCell: EducationDistributionJSON, educationDataByState: EducationDistributionJSON): void {
    const educationDistributionByCell: Record<string, number> = {
      [LESS_THAN_HIGH_SCHOOL]: 0,
      [HIGH_SCHOOL_GRADUATE]: 0,
      [COLLEGE_OR_ASSOCIATE]: 0,
      [BACHELOR]: 0,
      [GRADUATE_OR_PROFESSIONAL]: 0
    };

    const educationDistributionByUSA: Record<string, number> = {
      [LESS_THAN_HIGH_SCHOOL]: 0,
      [HIGH_SCHOOL_GRADUATE]: 0,
      [COLLEGE_OR_ASSOCIATE]: 0,
      [BACHELOR]: 0,
      [GRADUATE_OR_PROFESSIONAL]: 0
    };

    storeEducationData(educationDataByCell.distribution, educationDistributionByCell)
    storeEducationData(educationDataByState.distribution, educationDistributionByUSA)

    function storeEducationData(data: EducationDistribution[], storage: Record<string, number>): void {
      data.forEach(data => {
        switch (data.education) {
          case 'LESS_9_GRADE':
          case 'NINE_TO_12_GRADE_NO_DIPLOMA':
            storage[LESS_THAN_HIGH_SCHOOL] += data.population;
            break;
          case 'HIGH_SCHOOL':
            storage[HIGH_SCHOOL_GRADUATE] += data.population;
            break;
          case 'ASSOCIATE':
          case 'COLLEGE':
            storage[COLLEGE_OR_ASSOCIATE] += data.population;
            break;
          case 'BACHELOR':
            storage[BACHELOR] += data.population;
            break;
          case 'PROFESSIONAL':
            storage[GRADUATE_OR_PROFESSIONAL] += data.population;
            break;
          default:
            console.error('Unknown education category:', data.education);
        }
      })
    }

    const categories = Object.keys(educationDistributionByCell);

    const populationsByCell = Object.values(educationDistributionByCell);
    const totalValuesByCell = populationsByCell.reduce((acc, curr) => acc + curr, 0)
    const populationsPercentsByCell = populationsByCell.map(el => {
      return (el / totalValuesByCell) * 100
    })

    const populationsByUSA = Object.values(educationDistributionByUSA);
    const totalValuesByUSA = populationsByUSA.reduce((acc, curr) => acc + curr, 0)
    const populationsPercentsByUSA = populationsByUSA.map(el => {
      return (el / totalValuesByUSA) * 100
    })

    if (totalValuesByCell < 10) {
      this.featureStatus.containsEducationAttainment = false
      return
    }

    this.featureStatus.containsEducationAttainment = true

    this.educationAttainmentChart.data = [{
      name: 'Cell',
      x: categories,
      y: populationsPercentsByCell,
      type: 'bar',
      customdata: populationsByCell.map((population, i) => `${((population / totalValuesByCell) * 100).toFixed(1)}%`),
      hovertemplate: `%{customdata}<extra></extra>`,
      marker: {
        color: categories.map((value) => {
          switch (value) {
            case LESS_THAN_HIGH_SCHOOL:
              return '#A084BD';
            case HIGH_SCHOOL_GRADUATE:
              return '#8561AA';
            case COLLEGE_OR_ASSOCIATE:
              return '#6B4790';
            case BACHELOR:
              return '#5F3F80';
            case GRADUATE_OR_PROFESSIONAL:
              return '#533770';
            default:
              return;
          }
        })
      }
    }, {
      name: 'National',
      x: categories,
      y: populationsPercentsByUSA,
      type: 'bar',
      opacity: 0.5,
      customdata: populationsByUSA.map((population, i) => `${((population / totalValuesByUSA) * 100).toFixed(1)}%`),
      hovertemplate: `%{customdata}<extra></extra>`,
      marker: {
        color: categories.map((value) => {
          switch (value) {
            case LESS_THAN_HIGH_SCHOOL:
              return '#A084BD';
            case HIGH_SCHOOL_GRADUATE:
              return '#8561AA';
            case COLLEGE_OR_ASSOCIATE:
              return '#6B4790';
            case BACHELOR:
              return '#5F3F80';
            case GRADUATE_OR_PROFESSIONAL:
              return '#533770';
            default:
              return;
          }
        })
      }
    }];

    this.educationAttainmentChart.layout = {
      autosize: true,
      legend: {
        x: -0.15,
        y: 1.2,
        orientation: 'h',
      },
      xaxis: {
        title: {
          text: 'Attainment',
          font: {
            color: '#fff',
            size: 1
          }
        },
        tickangle: 0,
        tickvals: categories,
        ticktext: categories,
        tickfont: {
          size: 10
        }
      },
      yaxis: {
        title: 'People',
        tickformat: ',.0f',
        ticksuffix: '%'
      },
      bargap: 0.4,
      barmode: 'group',
      margin: {t: 35, b: 55, r: 30},
      height: 320,
      width: 420,
      font: {
        family: 'Rising Sun, sans-serif'
      },
    };
  }
}
