<div class="plan-container" [ngClass]="isSubscriptionUrlSelected ? 'selected' : ''">
  <span class="plan-name" [innerHTML]="planInfo.preformattedPlanName"></span>

  <div class="price-wrapper" [ngClass]="!isPeriodMonthly ? 'price-with-discount' : ''"
       *ngIf="!planInfo.priceLifetime; else lifetime">
    <div *ngIf="!isPeriodMonthly" style="display:flex; gap: 10px">
      <span class="crossed-out-price">{{ planInfo.priceMonthAnnually }}</span>
      <span class="yearly-discount-percents">{{ planInfo.yearlyDiscountPercents }}</span>
    </div>
    <div class="current-price">
      <span class="price-value">{{ isPeriodMonthly ? planInfo.priceMonthly : planInfo.priceYearly }}</span>
      <span class="period">{{ isPeriodMonthly ? '/month' : '/year' }}</span>
    </div>
  </div>


  <button class="gradient-button"
          (click)="handleSubscriptionClick(planInfo.requestKey)"
          [disabled]="isThisSubscriptionActive"
  >
    {{ isThisSubscriptionActive ? 'Subscription is active' : (isPeriodMonthly ? 'Get started' : 'Buy now & save') }}
  </button>

  <div class="description">
    <div class="scrollable-content">
      <div class="accessible-feature">
        <mat-checkbox [checked]="true" [disabled]="true"></mat-checkbox>
        <span>{{ planInfo.accessibleFeaturesDescription.indicatorsDescription.description }}</span>
      </div>
      <button
        (click)="isSeeIndicatorsActive = !isSeeIndicatorsActive"
        class="show-indicators-btn">
        {{ isSeeIndicatorsActive ? 'Hide indicators' : 'See all indicators' }}
      </button>
      <ng-container *ngIf="isSeeIndicatorsActive">
      <mat-expansion-panel *ngFor="let group of planInfo.accessibleFeaturesDescription.indicatorsDescription.groups">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>{{ group.groupTitle }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li *ngFor="let item of group.bulletList">{{ item }}</li>
        </ul>
      </mat-expansion-panel>
      </ng-container>
      <div class="accessible-feature" *ngFor="let feature of planInfo.accessibleFeaturesDescription.plainTextFeatureDescription">
        <mat-checkbox [checked]="true" [disabled]="true"></mat-checkbox>
        <span>{{ feature }}</span>
      </div>

      <ng-container *ngIf="planInfo.accessibleFeaturesDescription.reportTypesDescription as reportTypesDescription">

      <div class="accessible-feature" *ngFor="let report of !reportTypesDescription.reportsMonthly ? reportTypesDescription.reportsBase : (isPeriodMonthly ? reportTypesDescription.reportsMonthly : reportTypesDescription.reportsYearly ); last as isLast">
        <mat-checkbox *ngIf="report.reportsIncludedDescription" [checked]="true" [disabled]="true"></mat-checkbox>
        <mat-checkbox *ngIf="report.reportsPaidDescription && !report.includedInSubscription" [checked]="false" [disabled]="true"></mat-checkbox>

        <div class="reports-description">
          <span *ngIf="report.reportsIncludedDescription">{{ report.reportsIncludedDescription }}</span>
          <span *ngIf="report.reportsPaidDescription" class="not-included-reports">{{ report.reportsPaidDescription }}</span>
          <a *ngIf="report.sampleURL" class="link-like-text" href="{{report.sampleURL}}" target="_blank">Sample report</a>
          <span *ngIf="!report.isReadyToOrder" style="color: var(--typography-gray);" [ngStyle]="{'height': isLast ? '80px' : ''}">(Contact us at <a class="link-like-text" href="mailto:info@map-ai.co">info@map-ai.co</a> to order)</span>
        </div>

      </div>
      </ng-container>

      <ng-container>
        <div class="accessible-feature inaccessible-feature"
             *ngFor="let description of planInfo.inaccessibleFeatureText">
          <mat-checkbox [disabled]="true"></mat-checkbox>
          <span>{{ description }}</span>
        </div>
      </ng-container>
    </div>

  </div>
</div>

<ng-template #lifetime>
  <div class="price-wrapper">
    <span>{{ planInfo.priceLifetime }}</span>
  </div>
</ng-template>
