import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ColorscaleService } from './colorscale.service';
import { LayerStoreService } from '../../services/layer-store.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { SelectedCellService } from '../../services/selected-cell.service';
import {
  featuresToMultiplyBy100,
  writtenInStateFeatures,
} from '../../../../shared/types/feature-data-type';
import { MapBoxService } from '../../mapbox.service';
import { LevelLayers } from '../level-selection/levels';

export const WHITE = '#fff';

@Component({
  selector: 'app-colorscale',
  templateUrl: './color-scale.component.html',
  styleUrls: ['./color-scale.component.scss'],
})
export class ColorScaleComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() colors: any[][] = [];

  @Input() isCommonColorScale: boolean = false

  public currentFeatureValue!: number

  public activeLevel = '';

  isBeingLoaded: boolean = false;

  public currentFeatureValueCirclePosition!: number | null
  public currentFeatureValueCircleIndex!: number

  constructor(public colorScaleService: ColorscaleService,
              public layerStore: LayerStoreService,
              private mapboxService: MapBoxService,
              private selectedCellService: SelectedCellService,
              private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.colorScaleService.isBeingLoaded
      .pipe(takeUntil(this.destroy$), debounceTime(150))
      .subscribe(isBeingLoaded => {
        this.isBeingLoaded = isBeingLoaded
        if (!isBeingLoaded) {
          this.handleFeatureValue()

          this.cdr.detectChanges()
        }
      })

    this.selectedCellService.selectedCellGeoId
      .pipe(takeUntil(this.destroy$), debounceTime(150))
      .subscribe(id => {
        if (id) {
          this.handleFeatureValue()
        }
      })

    this.layerStore.activeLevel.subscribe(nextLevel => {
      // @ts-ignore
      this.activeLevel = LevelLayers[nextLevel];
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  isTransitionFromOtherLayer(): boolean {
    return this.colorScaleService.colorScaleForFeature !== this.layerStore.activeLayer.value;
  }

  private calculateCurrentFeatureValueCirclePosition(): void {
    const colors = this.colors;

    if (!this.currentFeatureValue || !this.colors.length || !colors) {
      return
    }

    let closestBottomIndex: number | undefined;
    let closestTopIndex: number | undefined;

    // Detect if we should multiply all values by 100 for index, trends and other layers like that
    const multiply = featuresToMultiplyBy100.includes(this.layerStore.activeLayer.value)
    const currentValue = multiply ? this.currentFeatureValue * 100 : this.currentFeatureValue

    // Define min bound as we dont have access to lowest value on map and colors[0][0] is a top bound
    const isMinBoundNegative = colors[0][0] < 0
    const minBound = !isMinBoundNegative ? colors[0][0] * 0.5 : colors[0][0] * 1.5

    colors.forEach((colorBracket, index) => {
      if ((multiply ? colorBracket[0] * 100 : colorBracket[0]) <= currentValue) {
        closestBottomIndex = index;
      }
      if ((multiply ? colorBracket[0] * 100 : colorBracket[0]) >= currentValue && closestTopIndex === undefined) {
        closestTopIndex = index;
      }
    });

    if (closestBottomIndex === undefined) {
      closestBottomIndex = 0;
    }

    if (closestTopIndex === undefined) {
      closestTopIndex = colors.length - 1;
    }

    this.currentFeatureValueCircleIndex = closestTopIndex;

    this.currentFeatureValueCirclePosition = this.getRelativePosition(
      closestBottomIndex === 0 ? (multiply ? minBound * 100 : minBound) : (multiply ? colors[closestBottomIndex][0] * 100 : colors[closestBottomIndex][0]),
      multiply ? colors[closestTopIndex][0] * 100 : colors[closestTopIndex][0],
      currentValue,
      colors.length
    );
  }

  private getRelativePosition(botBound: number, topBound: number, currentValue: number, colorsLen: number): number | null {
    if (typeof botBound !== "number" || typeof topBound !== "number") return null;

    // Highest and lowest left% value to render circle
    const highestPos = this.currentFeatureValueCircleIndex === colorsLen - 1 ? 75 : 100;
    const lowestPos = this.currentFeatureValueCircleIndex === 0 ? 25 : 0;

    const range = topBound - botBound;
    let position = ((currentValue - botBound) / range) * 100;

    // Ensure the position is within the bounds of lowestPos and highestPos
    if (position > highestPos) position = highestPos;
    if (position < lowestPos) position = lowestPos;

    return position;
  }

  private handleFeatureValue(): void {
    const activeLayer = this.layerStore.activeLayer.value
    const selectedFeature = this.selectedCellService.getSelectedFeature()

    if (!selectedFeature) return

    if (writtenInStateFeatures.includes(activeLayer)) {
      // If layer data is stored in feature.state, use getFeatureState instead of directly accessing feature.state
      this.currentFeatureValue = this.mapboxService.map.getFeatureState(selectedFeature)[activeLayer]
      this.calculateCurrentFeatureValueCirclePosition()
    } else {
      this.currentFeatureValue = selectedFeature!.properties![activeLayer]
      this.calculateCurrentFeatureValueCirclePosition()
    }
  }

  protected readonly Math = Math;
}
