<ng-container [ngSwitch]="layer">



<div *ngSwitchCase="PRESIDENTIAL_ELECTIONS_RESULTS2020">
  <h4>Presidential elections 2020:</h4>
  <ul>
    <li *ngIf="properties.BIDEN_ABSOLUTE2020">Biden: <span>{{properties.BIDEN_ABSOLUTE2020}} - {{properties.BIDEN_PERCENTAGE2020.toFixed(1)}}%</span></li>
    <li *ngIf="properties.TRUMP_ABSOLUTE2020">Trump: <span>{{properties.TRUMP_ABSOLUTE2020}} - {{properties.TRUMP_PERCENTAGE2020.toFixed(1)}}%</span></li>
    <li *ngIf="properties.JORGENSEN_ABSOLUTE2020">Jorgensen: <span>{{properties.JORGENSEN_ABSOLUTE2020}} - {{properties.JORGENSEN_PERCENTAGE2020.toFixed(1)}}%</span></li>
    <li *ngIf="properties.OTHER_ABSOLUTE2020">Other: <span>{{properties.OTHER_ABSOLUTE2020}} - {{properties.OTHER_PERCENTAGE2020.toFixed(1)}}%</span></li>
  </ul>
</div>

<div *ngSwitchCase="PRESIDENTIAL_ELECTIONS_RESULTS2024">
  <h4>Presidential elections 2024:</h4>
  <ul>
    <li *ngIf="properties.HARRIS_ABSOLUTE2024">Harris: <span>{{properties.HARRIS_ABSOLUTE2024}} - {{properties.HARRIS_PERCENTAGE2024.toFixed(1)}}%</span></li>
    <li *ngIf="properties.TRUMP_ABSOLUTE2024">Trump: <span>{{properties.TRUMP_ABSOLUTE2024}} - {{properties.TRUMP_PERCENTAGE2024.toFixed(1)}}%</span></li>
    <li *ngIf="properties.OLIVER_ABSOLUTE2024">Oliver: <span>{{properties.OLIVER_ABSOLUTE2024}} - {{properties.OLIVER_PERCENTAGE2024.toFixed(1)}}%</span></li>
    <li *ngIf="properties.STEIN_ABSOLUTE2024">Stein: <span>{{properties.STEIN_ABSOLUTE2024}} - {{properties.STEIN_PERCENTAGE2024.toFixed(1)}}%</span></li>
    <li *ngIf="properties.OTHER_ABSOLUTE2024">Other: <span>{{properties.OTHER_ABSOLUTE2024}} - {{properties.OTHER_PERCENTAGE2024.toFixed(1)}}%</span></li>
  </ul>
</div>

<div *ngSwitchCase="HOUSE_OF_REPRESENTATIVES_ELECTION_RESULTS">
  <h4>House elections:</h4>
  <ul>
    <li *ngIf="properties.DEMOCRAT_HOUSE_OF_REPRESENTATIVES_ABSOLUTE">Democrat: <span>{{properties.DEMOCRAT_HOUSE_OF_REPRESENTATIVES_ABSOLUTE}} - {{properties.DEMOCRAT_HOUSE_OF_REPRESENTATIVES_PERCENTAGE.toFixed(1)}}%</span></li>
    <li *ngIf="properties.REPUBLICAN_HOUSE_OF_REPRESENTATIVES_ABSOLUTE">Republican: <span>{{properties.REPUBLICAN_HOUSE_OF_REPRESENTATIVES_ABSOLUTE}} - {{properties.REPUBLICAN_HOUSE_OF_REPRESENTATIVES_PERCENTAGE.toFixed(1)}}%</span></li>
    <li *ngIf="properties.OTHER_HOUSE_OF_REPRESENTATIVES_ABSOLUTE">Other: <span>{{properties.OTHER_HOUSE_OF_REPRESENTATIVES_ABSOLUTE}} - {{properties.OTHER_HOUSE_OF_REPRESENTATIVES_PERCENTAGE.toFixed(1)}}%</span></li>
  </ul>
</div>

<div *ngSwitchCase="SENATE_ELECTION_2022_RESULTS">
  <h4>Senate elections:</h4>
  <ul>
    <li *ngIf="properties.DEMOCRAT_SENATE_2022_ABSOLUTE">Democrat: <span>{{properties.DEMOCRAT_SENATE_2022_ABSOLUTE}} - {{properties.DEMOCRAT_SENATE_2022_PERCENTAGE.toFixed(1)}}%</span></li>
    <li *ngIf="properties.REPUBLICAN_SENATE_2022_ABSOLUTE">Republican: <span>{{properties.REPUBLICAN_SENATE_2022_ABSOLUTE}} - {{properties.REPUBLICAN_SENATE_2022_PERCENTAGE.toFixed(1)}}%</span></li>
    <li *ngIf="properties.OTHER_SENATE_2022_ABSOLUTE">Other: <span>{{properties.OTHER_SENATE_2022_ABSOLUTE}} - {{properties.OTHER_SENATE_2022_PERCENTAGE.toFixed(1)}}%</span></li>
  </ul>
</div>

  <div *ngSwitchCase="BIDEN_VS_TRUMP_2020_POPULATION">
    <h4>Population by 2020 presidential election results:</h4>
    <ul>
      <li *ngIf="properties[BIDEN_VS_TRUMP_2020_POPULATION]">
        Population - <span>{{ Math.abs(properties[BIDEN_VS_TRUMP_2020_POPULATION]) | numberFormatter:0:POPULATION:true }}</span>
      </li>
      <li *ngIf="winnerPercentage2020">
        <span>{{ winnerCandidate2020 }} {{ winnerPercentage2020.toFixed(1) }}% votes</span>
      </li>
      <li *ngIf="populationVotedPercentage2020 > 0">
        Population voted <span>{{ populationVotedPercentage2020.toFixed(1) }}%</span>
      </li>
    </ul>
  </div>

  <div *ngSwitchCase="HARRIS_VS_TRUMP_2024_POPULATION">
    <h4>Population by 2024 presidential election results:</h4>
    <ul>
      <li *ngIf="properties[HARRIS_VS_TRUMP_2024_POPULATION]">
        Population - <span>{{ Math.abs(properties[HARRIS_VS_TRUMP_2024_POPULATION]) | numberFormatter:0:POPULATION:true }}</span>
      </li>
      <li *ngIf="winnerPercentage2024">
        <span>{{ winnerCandidate2024 }} {{ winnerPercentage2024.toFixed(1) }}% votes</span>
      </li>
      <li *ngIf="populationVotedPercentage2024 > 0">
        Population voted <span>{{ populationVotedPercentage2024.toFixed(1) }}%</span>
      </li>
    </ul>
  </div>

<ng-template *ngSwitchDefault>
  <div>
    <h4>Population presidential election results:</h4>
    <ul>
      <li>
        Population - <span>{{ properties[POPULATION] | numberFormatter:0:POPULATION:true }}</span>
      </li>
      <li>
        <span>Election data not reported</span>
      </li>
    </ul>
  </div>
</ng-template>
</ng-container>
