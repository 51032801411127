import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../user/authentication.service';
import { REDIRECT_AFTER_LOGIN_PATH } from '../../app.component';
import { ModalService } from '../services/modal.service';
import { signInToAccessReports } from '../../user/map-redirect-modal/map-redirect-modal-text';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private modalService: ModalService
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const hasToken = this.authService.hasAccessToken();
    const path = route.path;

    if (!hasToken && path === 'reports') {
      this.modalService.openModal(signInToAccessReports);
    }

    return hasToken;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.authService.hasAccessToken()) {
      localStorage.setItem(
        REDIRECT_AFTER_LOGIN_PATH,
        window.location.pathname + window.location.search
      );

      this.router.navigate(['sign-in']);
      return false;
    }

    return true;
  }
}
