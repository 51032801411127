
<div class="modal">
  <button *ngIf="!disableClose" class="close-button" (click)="close()">
    <img src="assets/icons/Cancel.svg" alt="close window" />
  </button>

  <h3 *ngIf="headerText">{{ headerText }}</h3>

  <p *ngIf="subheaderText" style="margin-bottom: -10px">
    {{ subheaderText }}
  </p>

  <p *ngIf="mainText">
    <ng-container *ngIf="!linkTextInMainText">
      {{ mainText }}
      <a *ngIf="linkText" (click)="goToLink()">{{ linkText }}.</a>
    </ng-container>

    <ng-container *ngIf="linkTextInMainText">
      {{ mainTextBeforeLink }}
      <a (click)="goToLink()">{{ linkTextInMainText }}</a>{{ mainTextAfterLink }}
    </ng-container>
  </p>

  <button *ngIf="buttonText" class="gradient-button" (click)="goToButton()">
    {{ buttonText }}
  </button>

  <div class="disclaimer-text-container" *ngIf="disclaimerText">
    <button class="disclaimer-button" (click)="isDisclaimerOpened= !isDisclaimerOpened">
      {{ isDisclaimerOpened ? 'Hide Disclaimer' : 'Disclaimer' }}
    </button>
    <p *ngIf="isDisclaimerOpened">{{ disclaimerText }}</p>
  </div>
</div>
