<ng-container *ngIf="accessService.isUserPlanUpdated | async as isUserPlanUpdated">
  <ng-container *ngIf="layerStore.activeLevel | async as activeLevel">
    <ng-container *ngIf="layerStore.activeLayer | async as activeLayer">
      <div
        *ngIf="((activeMenuGroup === MAPS_GROUP && groupData.data.mapColor) && ((mapColorFeatureList | isGroupAccessible: isUserPlanUpdated) || !isPaidPlan) ||
        (activeMenuGroup === CHARTS_GROUP && groupData.data.charts) && ((chartFeatureList | isGroupAccessible: isUserPlanUpdated) || !isPaidPlan) ||
        (activeMenuGroup === PLACES_GROUP && groupData.data.places) && ((placesFeatureList | isGroupAccessible: isUserPlanUpdated) || !isPaidPlan))"
        [ngClass]="{'layer-opened': isFeatureVisible}"
        class="layers-info-container">
        <div class="layers-info-title" (click)="toggleGroup()">
          <button mat-icon-button>
            <mat-icon
              [ngClass]="{ 'opened-arrow-icon': isFeatureVisible }"
              class="arrow-right-icon"
              svgIcon="arrow_right"
            >
            </mat-icon>
          </button>
          {{ groupData.title | groupTitleFormatter }}
          <app-feature-tooltip *ngIf="groupData.tooltip" [tooltipText]="groupData.tooltip.text"
                               [tooltipRouterLink]="groupData.tooltip.routerLink"></app-feature-tooltip>
        </div>

        <div class="scope-container"
             *ngIf="groupData.scope && isFeatureVisible && !(activeLevel === STATE_LEVEL_LAYER)">

          <mat-radio-group [(ngModel)]="selectedScope" (change)="onScopeToggle($event.value)">
            <mat-radio-button
              class="scope-radio-button"
              [value]="FeatureScope.BY_COUNTRY"
              [checked]="selectedScope === FeatureScope.BY_COUNTRY">
              National
            </mat-radio-button>
            <mat-radio-button
              class="scope-radio-button"
              [value]="FeatureScope.BY_STATE"
              [checked]="selectedScope === FeatureScope.BY_STATE">
              By state
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div *ngIf="isFeatureVisible && activeMenuGroup === MAPS_GROUP && groupData.data.mapColor"
             class="indexes-group-form toggle-wrapper">
          <span class="poi-disclaimer"
                *ngIf="groupData.disclaimer && (!groupData.disclaimer.visibleOnLevels || groupData.disclaimer.visibleOnLevels?.includes(activeLevel))" [attr.data-cy]="'visible-on-levels-disclaimer'">{{ groupData.disclaimer.text }}</span>
          <ng-container *ngFor="let feature of groupData.data.mapColor; trackBy: identifyByConst">
          <span class="poi-disclaimer"
                *ngIf="feature.disclaimer && feature.disclaimer.visibleOnLevels?.includes(activeLevel!)">{{ feature.disclaimer.text }}</span>

            <mat-radio-button
              *ngIf="!isPaidPlan || isPaidPlan && (feature.featureConst | isFeatureAccessible: isUserPlanUpdated)"
              [checked]="groupData.scope ? activeLayer === feature.featureConst + selectedScope : activeLayer === feature.featureConst"
              (change)="onActiveLayerToggle(groupData.scope ? feature.featureConst + selectedScope : feature.featureConst)"
              (click)="handleRadioButtonClick(); handleInaccessibleFeatureClick(feature.featureConst, feature.featureName)"
              class="menu-toggle"
              [value]="groupData.scope ? feature.featureConst + selectedScope : feature.featureConst"
              [disabled]="feature.accessibleOnLevels && !feature.accessibleOnLevels.includes(activeLevel!) || !(feature.featureConst | isFeatureAccessible: isUserPlanUpdated) || accessService.isFeaturesClickLimitExceeded && accessService.isUnauthorized"
              [matTooltip]="!(feature.featureConst | isFeatureAccessible: isUserPlanUpdated) ? (authService.hasAccessToken() ? 'Subscribe'  : 'Sign in') + ' to access this parameter' : ''"
              [matTooltipPosition]="'above'"
              [attr.data-cy]="feature.e2eName"
            ><span class="right-menu-category-elements">{{ feature.featureConst | LayerNameFormatPipe }}</span>

              <app-feature-tooltip *ngIf="feature.tooltip"
                                   [isFeatureInaccessibleType]="!(feature.featureConst | isFeatureAccessible: isUserPlanUpdated)"
                                   [headerText]="feature.tooltip.headerText"
                                   [tooltipText]=feature.tooltip.text></app-feature-tooltip>
            </mat-radio-button>
            <app-colorscale
              *ngIf="groupData.scope ? activeLayer === feature.featureConst + selectedScope : activeLayer === feature.featureConst"
              [colors]="colorScaleService.colorScaleColors"
              class="colorscale"
            ></app-colorscale>
          </ng-container>
        </div>

        <div class="charts-container toggle-wrapper"
             *ngIf="isFeatureVisible && activeMenuGroup === CHARTS_GROUP && groupData.data.charts">
          <ng-container *ngFor="let chart of groupData.data.charts; trackBy: identifyByConst">

            <app-chart-block
              *ngIf="!isPaidPlan || isPaidPlan && (chart.featureConst | isFeatureAccessible: isUserPlanUpdated)"
              [chart]="chart"
              [featureStatus]="groupData.featureStatus!"
              [toggle]="toggleMap.get(chart.featureConst)!"
              (changeSelectedYear)="onSelectedYearChange($event)"
              (click)="handleInaccessibleFeatureClick(chart.featureConst, chart.toggleTitle)"
              [disabled]=" !(chart.featureConst | isFeatureAccessible: isUserPlanUpdated) || accessService.isFeaturesClickLimitExceeded && accessService.isUnauthorized"
            ></app-chart-block>
          </ng-container>
        </div>

        <div class="charts-container toggle-wrapper"
             *ngIf="isFeatureVisible && activeMenuGroup === PLACES_GROUP && groupData.data.places">

          <app-feature-disclaimer
            *ngIf="groupData.disclaimer && (layerStore.currentZoom! < groupData.disclaimer.visibleOnZoom?.min!)"
            [disclaimer]="groupData.disclaimer"></app-feature-disclaimer>

          <ng-container *ngFor="let feature of $any(groupData.data.places); trackBy: identifyByConst">

            <app-feature-disclaimer
              *ngIf="feature.disclaimer && (layerStore.currentZoom! < feature.disclaimer.visibleOnZoom?.min!)"
              [disclaimer]="feature.disclaimer"></app-feature-disclaimer>

            <mat-slide-toggle [formControl]="placesToggleMap.get(feature.featureConst)!"
                              *ngIf="$any(feature).featureName"
                              (change)="handlePointsToggleChange($event)"
                              [disabled]="!(feature.featureConst | isFeatureAccessible: isUserPlanUpdated) || accessService.isFeaturesClickLimitExceeded && accessService.isUnauthorized">
              {{ $any(feature).featureName }}
              <app-feature-tooltip [tooltipText]="feature.tooltip.text"
                                   [isFeatureInaccessibleType]="!(feature.featureConst | isFeatureAccessible: isUserPlanUpdated)"
                                   [headerText]="feature.tooltip.headerText"></app-feature-tooltip>
            </mat-slide-toggle>

            <app-chart-block
              *ngIf="!isPaidPlan || isPaidPlan && ((feature.featureConst | isFeatureAccessible: isUserPlanUpdated) && $any(feature).toggleTitle)"
              [chart]="$any(feature)"
              [featureStatus]="groupData.featureStatus!"
              [toggle]="toggleMap.get($any(feature).featureConst)!"
              (changeSelectedYear)="onSelectedYearChange($event)"
              (click)="handleInaccessibleFeatureClick(feature.featureConst, feature.featureName || feature.toggleTitle)"
              [disabled]=" !(feature.featureConst | isFeatureAccessible: isUserPlanUpdated) || accessService.isFeaturesClickLimitExceeded && accessService.isUnauthorized"
            ></app-chart-block>
          </ng-container>
        </div>

      </div>
    </ng-container>
  </ng-container>
</ng-container>
